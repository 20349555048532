import React from 'react';
import { useLocation } from 'react-router-dom';
import './ConfirmPage.css';

function ConfirmPage() {
  const location = useLocation();
  const { imageSrc } = location.state;

  const handleSubmit = () => {
    alert('Submitted!');
    // Implement actual submit logic here
  };

  return (
    <div className="confirm-container">
      <p>Confirm Your Selfie</p>
      <img src={imageSrc} alt="Selfie Preview" />
      <button className="button submit" onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default ConfirmPage;
