import React from 'react';
import ReactDOM from 'react-dom';

import Card from './Card';
import Button from './Button';
import classes from './ErrorModal.module.css';

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onCancel} />;
};


const ModalOverlay = (props) => {

  const handleInputFocus = () => {
    props.clearError();
  };
  
  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h3>Create a new folder</h3>
      </header>
      <div className={classes.content}>
        {props.errorMessage && /\w+/.test(props.errorMessage) && (
        <p className={classes.errorMessage}>{props.errorMessage}</p>
      )}
        <input
          type="text"
          placeholder="Enter folder name"
          value={props.folderName}
          onChange={props.onChange}
          onFocus={handleInputFocus}
        />
      </div>
      <footer className={classes.actions}>
        {/* <Button onClick={props.onCancel}>Cancel</Button>
        <Button onClick={props.onConfirm}>Create</Button> */}
        <button className={classes.cancel} onClick={props.onCancel}>Cancel</button>
        <button className={classes.create} onClick={props.onConfirm}>Create</button>
      </footer>
    </Card>
  );
};

const ErrorModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onCancel={props.onCancel} />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          errorMessage={props.errorMessage}
          title={props.title}
          message={props.message}
          onCancel={props.onCancel}
          onConfirm={props.onConfirm}
          onChange={props.onChange}
          folderName={props.folderName}
          clearError={props.clearError} 
        />,
        document.getElementById('overlay-root')
      )}
    </React.Fragment>
  );
};

export default ErrorModal;