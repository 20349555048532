import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    _id: '',
    userId: '',
    selectedItem: '',
    Groom: '',
    Bride: '',
    EventName: '',
    Venue: '',
    ClientName: '',
    Email: '',
    Date: '',
    Source: '',
    Phone: '',
    __v: 0
  },
  me: {},
  Album: {},
  PhotoSelection: {},
  selected: {
    parent: '',
    selection: ''
  },
  userPhotoSelect:[],
  uploads: {  },
  totalUploads:''
};


const formatDateString = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    replaceUser(state, action) {
      const newItem = action.payload;
      console.log('From replaceUser');
      console.log(newItem);
      const formattedDate = formatDateString(newItem.Date);

      state.user = {
        ...newItem,
        Date: formattedDate,
      };
    },
    firstfiles(state, action) {
      state.Album = action.payload[0];
      state.PhotoSelection = action.payload[1];
    },
    updateSelected(state, action) {
      const { parent, selection } = action.payload;
      state.selected = {
        parent,
        selection,
      };
    },
    setUploadProgress(state, action) {
      const { fileName, progress, status } = action.payload;
      state.uploads[fileName] = { progress, status };
    }, 
     setTotalUploads(state, action) {
      const Total = action.payload;
      state.totalUploads= Total;
    },
    clearUploads(state) {
      state.uploads = {};
    },
    userSelectionResponse(state, action) {
      const { images } = action.payload;  
      state.userPhotoSelect.push(...images);
    },
    getMe(state, action) {
      console.log('ACTION FROM SLICE');
      console.log(action.payload);
      const ME = action.payload;
      state.me = {
        ...ME,
      };
    }
  },
});

export const UserActions = UserSlice.actions;

export default UserSlice;
