import React from 'react';
import styles from './Settings.module.css';
import { CgProfile } from 'react-icons/cg';
import { CiLock } from "react-icons/ci";
import { MdPayment } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function Settings() {
    const navigate = useNavigate();

    const redirectToOption = (option) => {
        navigate(`/services/settings/${option.toLowerCase()}`);
    };

    const Logout =()=>{
        axios.get('https://api.hapzea.com/api/v1/user/logout',
        {withCredentials: true}
        )
        .then(response => {
            console.log('Logout successful');
            navigate('/login');
        })
        .catch(error => {
            console.error('Error logging out:', error);
        });  
        // Cookies.remove('https://hapzea.com', { path: '/services', secure: true, sameSite: 'strict' });
        // const cookieName = 'localhost';
  
        // document.cookie = `${cookieName}=; expires=0; path=/;`;
        // console.log('Logout successful');

        // Redirect to the login page or perform any other necessary actions
        // window.location.href = '/login'
    }

    return (
        <div className={styles.settings}>
            <div className={styles.settingsContainer}>
                <div className={styles.Bordery}> 
                    <div className={styles.UnderLine}></div>
                    <div className={styles.SettingsOptions} onClick={() => redirectToOption('Profile')}>
                        <p className={styles.optionText}><CgProfile className={styles.IconSettings} />Profile</p>
                    </div>
                    <div className={styles.UnderLine}></div>
                    <div className={styles.SettingsOptions} >
                        <p className={styles.optionText}><CiLock className={styles.IconSettings} />Security</p>
                    </div>
                    <div className={styles.UnderLine}></div>
                    <div className={styles.SettingsOptions} >
                        <p className={styles.optionText}><MdPayment className={styles.IconSettings} />Subscription</p>
                    </div>
                    <div className={styles.UnderLine}></div>
                    <div className={`${styles.SettingsOptions} ${styles.LogoutText}`} onClick={Logout}>
                        <p className={`${styles.optionText}`}><IoIosLogOut className={`${styles.IconSettings} ${styles.LogoutIcon}`} />Logout</p>
                    </div>
                    <div className={styles.UnderLine}></div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
