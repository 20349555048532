import React from 'react';
import './Scanner.css';
import { Outlet } from 'react-router-dom';

function Scanner() {
  return (
    <div>
      <Outlet />  // This will render FormPage, SelfiePage, or ConfirmPage based on the route
    </div>
  );
}

export default Scanner;
