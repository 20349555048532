import React, { useState } from 'react';
import styles from './SearchBar.module.css';
import { CiSearch } from 'react-icons/ci';

const SearchBar = ({ onSearch,width }) => {
    const [query, setQuery] = useState('');

    const handleSearch = () => {
        onSearch(query);
    };

    return (
        <div className={styles.search_bar} style={{width:`${width}` }}>
            <input
                type="text"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />
            {/* <button onClick={handleSearch}>Search</button> */}
            <span style={{ fontSize: '25px', color: '#007bff' }}>
                <CiSearch />
            </span>
        </div>
    );
};

export default SearchBar;
