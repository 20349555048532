import React from 'react';
import './DownloadPage.css';
import logo from '../img/logo_test_white.png';
import { LuDownload } from "react-icons/lu";
import { FaWindows } from "react-icons/fa";
import { LuExternalLink } from "react-icons/lu";

function DownloadPage() {
  return (
    <div>
      <div className='backdropie'>
        <div className='Download_logo'>
          <div className='Logos'>
            <li><a href="#home"><img className='logo_img' src={logo} alt="Logo" /></a></li>
          </div>
        </div>
        <div className='centerContainer'>
          <div className='center-content'>
            <div className='DownloadHead'>
              <p>Download the Desktop</p>
              <p>App Now!</p>
            </div>
            <div className='DownloadDetailing'>
            <p>To download the selected images with original resolution, please use our desktop application. It is easy to use and ensures you receive the highest quality images promptly. Watch Video Tutorial On How to use desktop application <span><a target="_blank" href='https://www.youtube.com/'><LuExternalLink /></a></span></p>
            </div>
            <div className='CenterConsole'>
              <div className='Windows-choose'>
                <p><span><FaWindows /></span>Windows (64 bit)</p>
              </div>
              <div className='DownloadBtn'>
                <a href="/Desktop_app/Hapzea Desktop SetupV1.0.exe" download>
                  <p>Download  <span><LuDownload /></span></p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadPage;
