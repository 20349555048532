import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import styles from './AlbumSubPage.module.css';
import axios from 'axios';

function AlbumSubPage() {
    const { id, businessName, EventName, folderName } = useParams();
    const [images, setImages] = useState([]);
    const [clickedImage, setClickedImage] = useState(null);

    const fetchMedia = async (id, main, select) => {
        try {
            const response = await axios.get(`https://api.hapzea.com/api/v1/user/fetchMedia`, {
                params: {
                    id: id,
                    main_folder: main,
                    sub_folder: select,
                },
                withCredentials: true,
            });
            const fetchedPhotos = response.data.data.files || [];
            setImages(fetchedPhotos.map((photo) => ({
                src: photo,
            })));
        } catch (error) {
            console.error("Error fetching media:", error);
        }
    };

    useEffect(() => {
        fetchMedia(id, 'Album', folderName);
    }, [id, folderName]);

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <div className={styles.gallery}>
            {clickedImage && (
                <div className={styles.previewOverlay} onClick={() => setClickedImage(null)}>
                    <div className={styles.preview}>
                        <img src={clickedImage} alt="Preview" />
                    </div>
                </div>
            )}
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className={styles.myMasonryGrid}
                columnClassName={styles.myMasonryGridColumn}
            >
                {images.map((each, i) => (
                    <div
                        key={i}
                        className={styles.galleryItem}
                        onClick={() => setClickedImage(each.src)}
                    >
                        <img src={each.src} alt={`Gallery Image ${i + 1}`} className={styles.galleryImage} />
                    </div>
                ))}
            </Masonry>
        </div>
    );
}

export default AlbumSubPage;
