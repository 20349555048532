import React, { useState, useEffect } from 'react';
import styles from './ClientGallary.module.css';
import axios from 'axios';
import { Outlet, useParams, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import logtail from '../logtail';
// import kerala from '../img/pexels-yuksel-kocaman-776590-1640057.jpg'

function ClientGallary() {
  const { id, businessName } = useParams();
  const [Albums, setNewFolderAlbums] = useState([]);
  const [permission, setPermission] = useState('');
  const [party, setParty] = useState({});
  const [loading, setLoading] = useState(true);
  const [coverPhotoMobUrl, setCoverPhotoMobUrl] = useState(''); // State for mobile cover photo URL
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // State for screen size
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    const fetchData = async () => {
      try {
        logtail.info('Calling validation');
        logtail.info({ businessName, id, type: "media" });
        const carrier = { businessName, id, type: "media" };
        const response = await axios.post('https://api.hapzea.com/api/v1/user/validatingLink', carrier, {
          withCredentials: true,
        });
        if (response.data.data.linkStatus === 'Allow Access') {
          logtail.info('Allow Access');
          setPermission(true);
        } else {
          logtail.info('No permission');
          setPermission(false);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        logtail.info('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [businessName, id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id !== undefined) {
          const response = await axios.get(`https://api.hapzea.com/api/v1/user/client/${id}`, {
            withCredentials: true,
          });
          if (response.data.data.client.Groom) {
            console.log(response.data.data.client.Bride);
            console.log(response.data.data.client.Groom);
            console.log('response.data.data.client.Bride');
            setParty({
              Bride: response.data.data.client.Bride,
              Groom: response.data.data.client.Groom
            });
          } else {
            setParty({
              client: response.data.data.client.ClientName
            });
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const shareMedia = async (id) => {
      if (id !== undefined) {
        try {
          const response = await axios.get(`https://api.hapzea.com/api/v1/user/metacheck/${id}`, {
            withCredentials: true,
          });
          const trimmedFolderPaths = response.data.data.map(path => path.replace(/\//g, ''));
          setNewFolderAlbums(trimmedFolderPaths);
        } catch (error) {
          console.error('Error sharing media:', error);
        }
      }
    };

    shareMedia(id);
    fetchData();
  }, [id]);



  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => setCoverPhotoMobUrl(src);
      img.onerror = () => console.error('Error loading image:', src);
    };
  
    const fetchCoverPhoto = async (id) => {
      try {
        const coverPhotoResponse = await axios.get(`https://api.hapzea.com/api/v1/user/getCoverPhotoMob?_id=${id}`, {
          responseType: 'blob',
          withCredentials: true,
        });
  
        const coverPhotoUrl = URL.createObjectURL(coverPhotoResponse.data);
        console.log('Fetched Cover Photo URL:', coverPhotoUrl);
  
        // Preload the image
        preloadImage(coverPhotoUrl);
  
      } catch (error) {
        console.error('Error fetching cover photo:', error);
        setCoverPhotoMobUrl(''); // Reset coverPhotoMobUrl if there's an error
      }
    };
  
    fetchCoverPhoto(id);
  
  }, [id]);

  //   const fetchLatestImage = async (id) => {
  //     try {
  //       await new Promise(resolve => setTimeout(resolve, 1000));

  //       const [coverPhotoMobResponse] = await Promise.all([
  //         axios.get(`https://api.hapzea.com/api/v1/user/getCoverPhotoMob?_id=${id}`, {
  //           responseType: 'blob',
  //           withCredentials: true,
  //         }),
  //       ]);

  //       // const coverPhotoUrl = URL.createObjectURL(coverPhotoResponse.data);
  //       const coverPhotoMobUrl = URL.createObjectURL(coverPhotoMobResponse.data);

  //       // console.log(coverPhotoUrl);
  //       console.log(coverPhotoMobUrl);

  //       setCoverPhotoMobUrl(coverPhotoMobUrl); // Set the mobile cover photo URL state
  //     } catch (error) {
  //       console.error('Error fetching photos:', error);
  //     }  
  //   };

  //   fetchLatestImage(id);

  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, [id]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>Loading...</div>
      ) : (
        permission === true ? (
          <div>
            <div className={styles.landingSelection}>
              <div className={styles.landingPartition}>
                <div className={styles.landingNames}>
                <h1 className={styles.landingTitle}>{`${party.Bride} & ${party.Groom}`}</h1>
                  <p className={styles.landingSubtitle}>Wedding Photography & Films {businessName}</p>
                </div>
                <div className={styles.landingPic}>
                <img src={coverPhotoMobUrl} alt="Cover Photo" />
                </div>
              </div>
            </div>
            <div className={styles.stickyNav}>
              <div className={styles.folders}>
                {Albums.map((folder, i) => {
                  const encodedFolder = encodeURIComponent(folder);
                  const path = `/${businessName}/${id}/just/${encodedFolder}`;
                  const isActive = location.pathname === path;
                  console.log(path);
                  return (
                    <NavLink
                    key={i}
                    to={`/${businessName}/${id}/just/${folder}`}
                    className={({ isActive }) => 
                      `${styles.navLink} ${isActive ? styles.activate : ''}`}
                    end
                  >
                    {folder}
                  </NavLink>
                  
                  );
                })}
                <div className={styles.sendPhotosContainer}>
                {/* <div className={styles.sendPhotos}>Send</div> */}
                </div>
              </div>
              <div className={styles.underline}></div>
            </div>
            <div className={styles.content}>
              <Outlet />
            </div>
          </div>
        ) : (
          <div className={styles.notAvailable}>Not available</div>
        )
      )}
    </div>
  );
}

export default ClientGallary;