import React from 'react'
import styles from './Operations.module.css';
import SearchBar from '../componets/SearchBar';

function Operation() {
  const searchBarWidth = '300px';
  return (
    <div className={styles.playground}>
      <div className={styles.sideOperations}>
        <div className={styles.searchbar}>
         <SearchBar width={searchBarWidth}/>
         </div>
         <div className={styles.AddProjectOpt}>
          <p>New Project</p>
          <img></img>
         </div>
         <div className={styles.projectsOpt}>
           <p>Projects</p>
           <div className={styles.dateOpt}>
            <h3>11 September 2023</h3>
            <p><span>3</span> projects</p>
           </div>
         </div>
         
      </div>
      <div className={styles.mainOperations}></div>
 
    </div>
  )
}

export default Operation