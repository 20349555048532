import React from 'react'
import Signup from '../componets/Signup'
import styles from './SignupPage.module.css'
import { Link } from 'react-router-dom';
import logo from '../img/logo_test_white.png';

function SignupPage() {
  return (
    <div className={styles.Signuppage}>
        <div className={styles.heroText}>
        <div className={styles.logo}>
              <li><a href="/"><img className='logo_img' src={logo} alt="Logo" /></a></li>
            </div>
        <p>Hello ! <br/>
          Welcome to<br/>
          Hapzea </p>
      </div>
      <div className={styles.heroLoginBox}>
      <Signup/>
      </div>
        </div>
  )
}

export default SignupPage