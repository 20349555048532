import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';
import logo from '../img/logo_test_white.png';
import { Twirl as Hamburger } from 'hamburger-react';

function Header() {
  const [fix, setFix] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setFix(window.scrollY >= 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setOpen(!isOpen);
    // Toggle expanded class on header_row
    const headerRow = document.querySelector(`.${styles.header_row}`);
    if (headerRow) {
      headerRow.classList.toggle('expanded', !isOpen);
    }
  };

  return (
    <header>
      <div className={`${fix ? styles.sticky : ''}`}>
        <div className={styles.container_header}>
          <div className={`${styles.header_row} ${isOpen ? 'expanded' : ''}`}>
            <div className={styles.logo}>
              <li><a href="#home"><img className='logo_img' src={logo} alt="Logo" /></a></li>
            </div>
            <nav>
              <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#features">Features</a></li>
                <li><a href="#pricing">Pricing</a></li>
                <li><a href="#about">About Us</a></li>
                <li className={styles.login}><a href="https://hapzea.com/login">Login</a></li>
                <li className={styles.signup}><a href="https://hapzea.com/signup">Sign Up</a></li>
              </ul>
            </nav>
            <div className={styles.mob_menu}>
              {/* <Hamburger toggled={isOpen} toggle={toggleMenu} color="#0bb2fc" /> */}
              <Hamburger toggled={isOpen} toggle={toggleMenu} color="#055bed" />
            </div>
            
          </div>
          
        </div>
        {isOpen ? <div className={styles.mob_menuOptions}>
        <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#features">Features</a></li>
                <li><a href="#pricing">Pricing</a></li>
                <li><a href="#about">About Us</a></li>
                <li className={styles.login}><a href="https://hapzea.com/login">Login</a></li>
                <li className={styles.signup}><a href="https://hapzea.com/signup">Sign Up</a></li>
              </ul> 
        </div>: ''}
      </div>
    </header>
  );
}

export default Header;
