  import React from 'react';
  import gicon from '../img/Gicon.png';
  import { useNavigate } from 'react-router-dom';
  import styles from './Google_auth.module.css'; // Ensure this path is correct
  import axios from 'axios';
  import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
  import { jwtDecode } from 'jwt-decode';

  function GoogleAuth() {
    const navigate = useNavigate();

    const handleLoginSuccess = async (response) => {
      console.log('RRRR');
      console.log(response);
      const userCredential = jwtDecode(response.credential);
      console.log(userCredential);

      const { email, sub: googleId } = userCredential; 
       console.log(email, googleId);
      localStorage.setItem('email', email);
      localStorage.setItem('id', googleId);

      try {
        const serverResponse = await axios.post(
          'https://api.hapzea.com/api/v1/user/googlesignIn',
          {
            email: localStorage.getItem('email'),
            id: localStorage.getItem('id'),
          },
          { withCredentials: true }
        );


        const EMAIL = serverResponse.data.data.user.email;
        if (serverResponse.data.token) {
          await axios.post(
            'https://api.hapzea.com/api/v1/user/login',
            {
              email: EMAIL, 
              password: 'Asdfghjklqwer2',
            },
            { withCredentials: true }
          ).then((res) => {
            console.log('about to go services');
            console.log(res.data);
            navigate('/services');
          }).catch((error) => {
            console.error(error);
          });
        }

        localStorage.removeItem('email');
        localStorage.removeItem('id');
      } catch (error) {
      }
    };

    const handleLoginFailure = (error) => {
     
    };

    const handleCustomButtonClick = () => {
      const googleButton = document.querySelector('.google-login-button');
      if (googleButton) {
        googleButton.click();
      }
    };
  
    return (
      <GoogleOAuthProvider clientId="494865887043-0gh1rjv4967gff6rfoffem1qp1mqs6fs.apps.googleusercontent.com">
        <div>
          <div className={styles.optionalsignup}>
            {/* <div onClick={handleCustomButtonClick}>
              <img className={styles.googleicon} src={gicon} alt="Google Sign-In" />
            </div>  */}
          </div>
          <div className={styles.optionalsignup}>
          <GoogleLogin 
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
            ux_mode="popup"
            className="google-login-button"
            style={{ display: 'none' }}
          />
          </div>
        </div>
      </GoogleOAuthProvider>
    );
  }

  export default GoogleAuth;
