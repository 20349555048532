import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FormPage.css';

function FormPage() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('selfie', { state: { name, phone } });
  };

  return (
    <div className="form-page">
      <div className="form-container">
        <h1>Welcome!</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="Enter Your Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <button type="submit">Next</button>
        </form>
      </div>
    </div>
  );
}

export default FormPage;
