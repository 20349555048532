import React from 'react';
import { useState } from 'react';
import styles from './Service.module.css'
import MediaClient from '../componets/MediaClient';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SideTab from '../componets/SideTab';
import { useParams } from 'react-router-dom';

function Events() {

  const location = useLocation();
   const {state} = location
   console.log('from Eventssssss 1');
   console.log(state);

  const { clientName, eventDate } = useParams();
  const { Groom, Bride, EventName, Time, Date, ClientName,magicLink } = useParams();
 
  console.log('from Eventssssss 2');
  console.log(Groom, Bride, EventName, Time, Date, ClientName );
  // console.log('########');
  // console.log(clientName,eventDate);

  const navigate = useNavigate();
  const [projectselect, setprojectselect] = useState(true);
  const [activeTab, setActiveTab] = useState('project');
 



  return (
    <div className={styles.service}> 
    
    <SideTab activeTab={activeTab}/>
      <div className={styles.maintab}>
        {projectselect ?
          <div className={styles.ProjectsEvents}>
            <MediaClient Onnames={state} />
          </div> : null}
      </div>
    </div>
  );
}

export default Events;
