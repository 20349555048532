import { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import logtail from './logtail';

const ProtectedRoute = () => {
  const [jwtToken, setJwtToken] = useState(null);
  const [isTokenSet, setIsTokenSet] = useState(false);

  useEffect(() => {
    const getJwtFromCookies = async () => {
      try {
        const response = await axios.get('https://api.hapzea.com/api/v1/user/protect', { withCredentials: true });
        console.log('from protect')
        console.log(response);
        logtail.info('from protect')
        logtail.info(response)
        if (response.data.message === 'Cookie not found') {
          console.log('cookies is not here'); 
          console.log(response.data.message);
          setJwtToken(null);
          setIsTokenSet(true);
        } else {
          console.log('cookies is here');
          console.log(response.data.jwtToken);
          setJwtToken(response.data.jwtToken);
          setIsTokenSet(true);  
        }
      } catch (err) {
        console.log(err.response.data.message);
        setJwtToken(null);
        setIsTokenSet(true);
      }
    };
    getJwtFromCookies();
  }, []);

  if (!isTokenSet) {
    return null; 
  }

  return jwtToken ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute; 

