import React, { useState, useEffect } from 'react';
import styles from './Guests_face.module.css';
import { FaWhatsapp } from 'react-icons/fa'; // Importing WhatsApp icon
import avat from './img/km.jpg'; // Placeholder avatar image

import img1 from './img/img1.jpeg'
import img2 from './img/img2.jpeg'
import img3 from './img/img3.jpeg'
import img4 from './img/img4.jpeg'
import img5 from './img/img5.jpeg'
import img6 from './img/img6.jpeg'
import img7 from './img/img7.jpeg'
import img8 from './img/img8.jpeg'
import img9 from './img/img9.jpg'
import img10 from './img/img10.jpg'

// Sample data for guests
const guests = [
    { id: 1, name: "Rohan", phone: "+91 9876 543 322", images: 8, avatar: avat },
    { id: 2, name: "Anika", phone: "+91 9123 456 789", images: 5, avatar: avat },
    { id: 3, name: "Vikram", phone: "+91 9988 776 655", images: 10, avatar: avat },
    { id: 4, name: "Sanya", phone: "+91 8877 665 544", images: 3, avatar: avat },
    { id: 5, name: "Amit", phone: "+91 7766 554 433", images: 3, avatar: avat },
    { id: 6, name: "Priya", phone: "+91 6655 443 322", images: 3, avatar: avat },
    { id: 7, name: "Karan", phone: "+91 5544 332 211", images: 3, avatar: avat },
    { id: 8, name: "Neha", phone: "+91 4433 221 100", images: 3, avatar: avat },
    { id: 9, name: "Rahul", phone: "+91 3322 110 099", images: 3, avatar: avat },
    { id: 10, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 11, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 12, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
    { id: 13, name: "Sneha", phone: "+91 2211 009 988", images: 3, avatar: avat },
];

// Hardcoded matched images for each guest
const matchedImages = {
    1: [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img1,
        img2,
        img3,
        img10,
        img1,
        img2,
        img3,
        img1,
        img2,
        img3,
        img10,
        img1,
        img2,
        img3,
        img2,
        img3,
        img1,
        img2,
        img3,
        img10,
        img1,
        img2,
        img3
    ],
    2: [
        "https://via.placeholder.com/300.png?text=Anika+1",
        "https://via.placeholder.com/300.png?text=Anika+2",
        "https://via.placeholder.com/300.png?text=Anika+3",
        "https://via.placeholder.com/300.png?text=Anika+4",
        "https://via.placeholder.com/300.png?text=Anika+5",
    ],
    3: [
        "https://via.placeholder.com/300.png?text=Vikram+1",
        "https://via.placeholder.com/300.png?text=Vikram+2",
        "https://via.placeholder.com/300.png?text=Vikram+3",
        "https://via.placeholder.com/300.png?text=Vikram+4",
        "https://via.placeholder.com/300.png?text=Vikram+5",
        "https://via.placeholder.com/300.png?text=Vikram+6",
        "https://via.placeholder.com/300.png?text=Vikram+7",
        "https://via.placeholder.com/300.png?text=Vikram+8",
        "https://via.placeholder.com/300.png?text=Vikram+9",
        "https://via.placeholder.com/300.png?text=Vikram+10",
    ],
    4: [
        "https://via.placeholder.com/300.png?text=Sanya+1",
        "https://via.placeholder.com/300.png?text=Sanya+2",
        "https://via.placeholder.com/300.png?text=Sanya+3",
    ],
    5: [
        "https://via.placeholder.com/300.png?text=Amit+1",
        "https://via.placeholder.com/300.png?text=Amit+2",
        "https://via.placeholder.com/300.png?text=Amit+3",
    ],
    6: [
        "https://via.placeholder.com/300.png?text=Priya+1",
        "https://via.placeholder.com/300.png?text=Priya+2",
        "https://via.placeholder.com/300.png?text=Priya+3",
    ],
    7: [
        "https://via.placeholder.com/300.png?text=Karan+1",
        "https://via.placeholder.com/300.png?text=Karan+2",
        "https://via.placeholder.com/300.png?text=Karan+3",
    ],
    8: [
        "https://via.placeholder.com/300.png?text=Neha+1",
        "https://via.placeholder.com/300.png?text=Neha+2",
        "https://via.placeholder.com/300.png?text=Neha+3",
    ],
    9: [
        "https://via.placeholder.com/300.png?text=Rahul+1",
        "https://via.placeholder.com/300.png?text=Rahul+2",
        "https://via.placeholder.com/300.png?text=Rahul+3",
    ],
    10: [
        "https://via.placeholder.com/300.png?text=Sneha+1",
        "https://via.placeholder.com/300.png?text=Sneha+2",
        "https://via.placeholder.com/300.png?text=Sneha+3",
    ],
    11: [
        "https://via.placeholder.com/300.png?text=Sneha+4",
        "https://via.placeholder.com/300.png?text=Sneha+5",
        "https://via.placeholder.com/300.png?text=Sneha+6",
    ],
    12: [
        "https://via.placeholder.com/300.png?text=Sneha+7",
        "https://via.placeholder.com/300.png?text=Sneha+8",
        "https://via.placeholder.com/300.png?text=Sneha+9",
    ],
    13: [
        "https://via.placeholder.com/300.png?text=Sneha+10",
        "https://via.placeholder.com/300.png?text=Sneha+11",
        "https://via.placeholder.com/300.png?text=Sneha+12",
    ],
};

export default function Guests() {
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState(null);

    const handleGuestClick = (guest) => {
        setSelectedGuest(guest);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedGuest(null);
        setIsModalOpen(false);
    };

    // Function to handle sending images via WhatsApp
    const handleSendWhatsApp = () => {
        if (selectedGuest) {
            // Construct the message with image URLs
            const imageLinks = matchedImages[selectedGuest.id]
                ?.map((link, index) => `Image ${index + 1}: ${link}`)
                .join('\n');
            const message = `Hello ${selectedGuest.name}, here are your images:\n${imageLinks}`;
            // Format the phone number by removing non-digit characters
            const formattedPhone = selectedGuest.phone.replace(/\D/g, '');
            const whatsappURL = `https://wa.me/${formattedPhone}?text=${encodeURIComponent(message)}`;
            const newWindow = window.open(whatsappURL, '_blank');
            if (newWindow) {
                // Optional: Provide user feedback
                // alert('WhatsApp message opened successfully.');
            } else {
                // Optional: Handle popup blocker
                alert('Failed to open WhatsApp. Please check your popup settings.');
            }
        }
    };

    // Handle Escape key to close modal
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    // Function to handle image click for lightbox
    const handleImageClick = (imgSrc) => {
        setLightboxImage(imgSrc);
        setLightboxOpen(true);
    };

    return (
        <>
            <div className={styles.header}>
                {/* <h2>Guest Gallery</h2> */}
                <p className={styles.total_guests}>Total Guests: {guests.length}</p>
            </div>
            <div className={styles.guests_container}>

                <div className={styles.guests_list_container}>
                    <div className={styles.guest_items}>
                        {guests.map((guest) => (
                            <div
                                key={`${guest.id}-${guest.name}-${guest.phone}`}
                                className={styles.guest_item}
                                onClick={() => handleGuestClick(guest)}
                            >
                                <img
                                    src={guest.avatar}
                                    alt={guest.name}
                                    onError={(e) => e.target.src = 'https://via.placeholder.com/80.png?text=No+Image'}
                                    loading="lazy"
                                    style={{
                                        borderRadius: '50%',
                                        width: '80px',
                                        height: '80px',
                                        objectFit: 'cover'
                                    }}
                                />

                                <div className={styles.guest_info}>
                                    <h4>{guest.name}</h4>
                                    {/* <p>{guest.phone}</p> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Modal for displaying matched images */}
                {isModalOpen && selectedGuest && (
                    <div
                        className={styles.modal}
                        onClick={closeModal}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-title"
                    >
                        <div
                            className={styles.modal_content}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <span
                                className={styles.close_button}
                                onClick={closeModal}
                                aria-label="Close Modal"
                            >
                                &times;
                            </span>
                            <div className={styles.modal_header}>
                                <h3 id="modal-title">{selectedGuest.name}'s Images</h3>
                                <div className={styles.whatsapp_section}>
                                    <FaWhatsapp className={styles.whatsapp_icon} />
                                    <span className={styles.whatsapp_number}>{selectedGuest.phone}</span>
                                    <button
                                        className={styles.send_button}
                                        onClick={handleSendWhatsApp}
                                    >
                                        <FaWhatsapp /> Send
                                    </button>
                                </div>
                            </div>
                            <div className={styles.images_container_whole}>
                                <div className={styles.modal_images_container}>
                                    {matchedImages[selectedGuest.id] ? (
                                        matchedImages[selectedGuest.id].map((imgSrc, index) => (
                                            <div className={styles.image_contents}>
                                                <img
                                                    key={index}
                                                    src={imgSrc}
                                                    alt={`${selectedGuest.name} ${index + 1}`}
                                                    className={styles.modal_image}
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(imgSrc)}
                                                    onError={(e) => e.target.src = 'https://via.placeholder.com/200x150.png?text=No+Image'}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <p>No images available.</p>
                                    )}
                                </div>
                            </div>

                            {/* <div className={styles.scroll_buttons}>
                            <button className={styles.scroll_top} onClick={() => {
                                const modal = document.querySelector(`.${styles.modal_content}`);
                                modal.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>
                                ↑ Top
                            </button>
                            <button className={styles.scroll_bottom} onClick={() => {
                                const modal = document.querySelector(`.${styles.modal_content}`);
                                modal.scrollTo({ top: modal.scrollHeight, behavior: 'smooth' });
                            }}>
                                ↓ Bottom
                            </button> 
                        </div> */}
                        </div>

                        {/* {lightboxOpen && (
                        <div className={styles.lightbox_overlay} onClick={() => setLightboxOpen(false)}>
                            <div className={styles.lightbox_content} onClick={(e) => e.stopPropagation()}>
                                <img src={lightboxImage} alt="Enlarged view" />
                                <button className={styles.lightbox_close} onClick={() => setLightboxOpen(false)}>
                                    &times;
                                </button>
                            </div>
                        </div>
                    )} */}
                    </div>
                )}
            </div>
        </>
    );
}
