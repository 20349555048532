import React from 'react';
import { useState, useEffect } from 'react';
import styles from './EditProfile.module.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SideTab from '../componets/SideTab';
import { useParams } from 'react-router-dom';
import backBtn from '../img/backBtn.svg';
import photo3 from '../img/user.png';
import photo2 from '../img/pexels-stefan-stefancik-91227.jpg';
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { UserActions } from '../Store/userSlice';
import { useDispatch } from 'react-redux';
import logtail from '../logtail';
import { IoCamera } from "react-icons/io5";

function EditProfile() {

    const dispatch = useDispatch();
    const [edit, setedit] = useState(false);
    // const [storeupdate, setstoreupdate] = useState('');
    const userStatus = useSelector(state => state.user.me);
    const [profilePicture, setProfilePicture] = useState(photo3);
    const [previewUrl, setPreviewUrl] = useState(null);

    const [formData, setFormData] = useState({
        businessName: '',
        email: '',
        address: '',
        website: '',
        googleMapLink: '',
        socialProfiles: {
            instagram: '',
            facebook: '',
            youtube: '',
            twitter: ''
        }
    });


    useEffect(() => {
        const fetchProfilePicture = async () => {
          try {
            const response = await axios.get(`https://api.hapzea.com/api/v1/user/profile_picture?id=${userStatus._id}`);
            if (response.status === 200) {
                console.log(response);
              setPreviewUrl(response.data.data.photoUrl);
            }
          } catch (error) {
            console.error('Error fetching profile picture:', error);
          }
        };
    
        fetchProfilePicture();
      }, []);

    useEffect(() => {
        if (userStatus) {
            setFormData({
                businessName: userStatus.businessName || '',
                email: userStatus.email || '',
                address: userStatus.address || '',
                website: userStatus.website || '',
                googleMapLink: userStatus.googleMapLink || '',
                socialProfiles: {
                    instagram: userStatus.socialProfiles?.instagram || '',
                    facebook: userStatus.socialProfiles?.facebook || '',
                    youtube: userStatus.socialProfiles?.youtube || '',
                    twitter: userStatus.socialProfiles?.twitter || ''
                }
            });
        }
    }, [userStatus]);

    const editProfile = () => {
        setedit(true)
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (["instagram", "twitter", "facebook"].includes(name)) {
            setFormData(prevState => ({
                ...prevState,
                socialProfiles: {
                    ...(prevState.socialProfiles || {}),
                    [name]: value
                }
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const canceling = () => {
        setedit(false);
        setFormData({
            businessName: userStatus.businessName !== '' ? userStatus.businessName : '',
            email: userStatus.email !== '' ? userStatus.email : '',
            address: userStatus.address !== '' ? userStatus.address : '',
            website: userStatus.website !== '' ? userStatus.website : '',
            googleMapLink: userStatus.googleMapLink !== '' ? userStatus.googleMapLink : '',
            socialProfiles: {
                instagram: userStatus.socialProfiles.instagram !== '' ? userStatus.socialProfiles.instagram : '',
                facebook: userStatus.socialProfiles.facebook !== '' ? userStatus.socialProfiles.facebook : '',
                youtube: userStatus.socialProfiles.youtube !== '' ? userStatus.socialProfiles.youtube : '',
                twitter: userStatus.socialProfiles.twitter !== '' ? userStatus.socialProfiles.twitter : ''
            }
        });
    };

    // useEffect(() => {
    //     if (storeupdate === 'Go') {
    const userDetails = async () => {
        try {
            logtail.info("called me");
            const response = await axios.get(
                'https://api.hapzea.com/api/v1/user/me',
                { withCredentials: true }
            );
            if (response.data.status === 'success') {
                console.log('FROM APP.JS');
                console.log(response.data.data);
                dispatch(UserActions.getMe(response.data.data.user));
                console.log('dispatched...........');
            }
        } catch (error) {
            console.log(error.response.data.message);
        }
    };

    // userDetails();
    // }
    // }, [storeupdate, dispatch]);


    const saving = async () => {
        setedit(false);
        console.log(formData);
        try {
            const response = await axios.post(`https://api.hapzea.com/api/v1/user/updateUser`, formData, { withCredentials: true });
            console.log('response:', response.data);
            console.log('User updated successfully');
            if (response.data.status === 'success') {
                await userDetails();
                logtail.info("called userDetails");
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    }

    const navigate = useNavigate()
    const location = useLocation();
    const pathname = location.pathname;
    const lastWord = pathname.split('/').pop();

    const [activeTab, setActiveTab] = useState('settings');


    const GoBack = () => {
        navigate('/services', {
            state: {
                team: false, home: false, project: false,
                help: false,
                status: false,
                setting: true,
                finance: false,
                active: 'settings',
                hover: false
            },
        });
    }


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const userId = userStatus._id;    // Replace with the actual user ID

        if (file) {
            const formData = new FormData();
            formData.append('photos', file);

            try {
                const response = await axios.post(`https://api.hapzea.com/api/v1/user/profile_upload?id=${userId}`, formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    // Use the photo URL returned from the server
                    console.log(response.data.photoUrl);
                    setPreviewUrl(response.data.photoUrl);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     console.log(userStatus._id);
    //     const userId = userStatus._id;  

    //     if (file) {
    //         const formData = new FormData();
    //         formData.append('photos', file);

    //         try {
    //             const response = await axios.post(`https://api.hapzea.com/api/v1/user/profile_upload?id=${userId}`, formData, {
    //                 withCredentials: true,
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 },
    //             });

    //             console.log(response.data);
    //             console.log('File uploaded successfully');
    //         } catch (error) {
    //             console.error('Error uploading file:', error);
    //         }
    //     }
    // };



    return (
        <div className={styles.editprofile}>
            <SideTab activeTab={activeTab} />
            <div className={styles.mainProfile}>
                <div className={styles.clientTitle}>
                    <div className={styles.topClients}>
                        <span onClick={GoBack}>
                            <img src={backBtn} />
                        </span>
                        {lastWord}
                    </div>
                </div>
                {lastWord === 'profile' ?
                    <>
                        <div className={styles.editprofileContainer}>
                            <div className={styles.editBtn} onClick={editProfile}>
                                <p>Edit</p>
                                <FaRegEdit />
                            </div>
                            <div className={styles.backDrop}>
                            </div>
                            <div className={styles.editprofileinner}>
                                <div className={styles.OuterContainer}>
                                    <div className={styles.OuterProfile}>
                                        <div className={`${styles.topProfile} `} >
                                            <div className={styles.profilepicture}>
                                                <img src={profilePicture} alt='profile-pic' />
                                                <div className={styles.overlay}>
                                                    {previewUrl && (
                                                        <div className={styles.previewContainer}>
                                                            <img src={previewUrl} alt="Profile Preview" className={styles.previewImage} />
                                                        </div>
                                                    )}
                                                  { !previewUrl && ( <IoCamera className={styles.cameraIcon} />)}
                                                    <span className={styles.uploadText}>Upload Photo</span>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleFileChange}
                                                        className={styles.fileInput}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className={styles.whole}>
                                        <div className={styles.one}>
                                            <div className={styles.formBox}>
                                                <label htmlFor="Name">Name</label>
                                                <input
                                                    type="text"
                                                    id="businessName"
                                                    name="businessName"
                                                    value={formData.businessName}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </div>
                                            <div className={styles.formBox}>
                                                <label htmlFor="email">Contact email</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={edit ? formData.email : (userStatus.email !== '' ? userStatus.email : '')}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />

                                            </div>

                                            <div className={styles.formBox}>
                                                <label htmlFor="Address">Address</label>
                                                <input
                                                    type="text"
                                                    id="address"
                                                    name="address"
                                                    value={edit ? formData.address : (userStatus.address !== '' ? userStatus.address : '')}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </div>
                                            <div className={styles.formBox}>
                                                <label htmlFor="Website">Website</label>
                                                <input
                                                    type="text"
                                                    id="website"
                                                    name="website"
                                                    value={edit ? formData.website : (userStatus.website !== '' ? userStatus.website : '')}
                                                    disabled={!edit}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className={styles.formBox}>
                                                <label htmlFor="GoogleMap">Google Map</label>
                                                <input
                                                    type="text"
                                                    id="googleMapLink"
                                                    name="googleMapLink"
                                                    value={edit ? formData.googleMapLink : (userStatus.googleMapLink !== '' ? userStatus.googleMapLink : '')}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.two}>
                                            <div className={styles.formBox}>
                                                <label htmlFor="instagram">instagram</label>
                                                <input
                                                    type="text"
                                                    id="instagram"
                                                    name="instagram"
                                                    value={edit ? formData.socialProfiles?.instagram || '' : (userStatus.socialProfiles?.instagram || '')}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </div>
                                            <div className={styles.formBox}>
                                                <label htmlFor="facebook">facebook</label>
                                                <input
                                                    type="text"
                                                    id="facebook"
                                                    name="facebook"
                                                    value={edit ? formData.socialProfiles?.facebook || '' : (userStatus.socialProfiles?.facebook || '')}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </div>
                                            <div className={styles.formBox}>
                                                <label htmlFor="youtube">youtube</label>
                                                <input
                                                    type="text"
                                                    id="youtube"
                                                    name="youtube"
                                                    value={edit ? formData.socialProfiles?.youtube || '' : (userStatus.socialProfiles?.youtube || '')}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </div>
                                            <div className={styles.formBox}>
                                                <label htmlFor="vimeo">twitter</label>
                                                <input
                                                    type="text"
                                                    id="twitter"
                                                    name="twitter"
                                                    value={edit ? formData.socialProfiles?.twitter || '' : (userStatus.socialProfiles?.twitter || '')}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    {edit ? <div className={styles.saving}>
                                        <div className={styles.cancel} onClick={canceling}>Cancel</div>
                                        <div className={styles.save} onClick={saving}>Save</div>
                                    </div> : ''}
                                </div>
                            </div>
                        </div>
                    </> : 'another'}
            </div>
        </div>
    )
}

export default EditProfile