// store.js
import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice'; // Make sure the import path is correct

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});

export default store;
