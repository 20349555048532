import React from 'react';
import styles from './Line.module.css' // Import your CSS file for styling

function Line({content}) {
  return (
    <div>
      <div className={styles.line_container}>
        <hr className={styles.horizontal_line} />
        <span className={styles.line_space}>{content}</span>
        <hr className={styles.horizontal_line} />
      </div>
    </div>
  );
}

export default Line;
