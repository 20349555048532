import React, { useState } from 'react';
import styles from './Signup.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Line from './Line';
import GoogleAuth from './Google_auth';

function Signup() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [mobile, setMobile] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [invalid, setInvalid] = useState('');

  const signupEmail = (e) => {
    setEmail(e.target.value);
  };

  const signupPassword = (e) => {
    setPassword(e.target.value);
  };

  const signupConfirmPassword = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const signupNumber = (e) => {
    setMobile(e.target.value);
  };

  const signupBusiness = (e) => {
    setBusinessName(e.target.value);
  };

  const clearErrorMessages = () => {
    setInvalid('');
  };

  const signupFun = async (e) => {
    e.preventDefault();
    console.log([businessName, email, mobile, password, passwordConfirm]);
    let signinStat;
    await axios.post('https://api.hapzea.com/api/v1/user/signup', {
      businessName,
      email,
      mobile,
      password,
      passwordConfirm
    })
      .then((response) => {
        console.log(response.data);
        signinStat = response.data.token;
      })
      .catch((error) => {
        console.error(error.response.data.message);
        setInvalid(error.response.data.message);
        console.log(invalid);
      });

    if (signinStat) {
      await axios.post('https://api.hapzea.com/api/v1/user/login', {
        email,
        password
      }, { withCredentials: true }).then(res => {
        console.log(res.data);
        navigate('/services');
      }).catch((error) => {
        console.error(error);
      });
    }
  };

  return (
    <div className={styles.SignupBoxBoundry}>
      <div className={styles.SignupBox}>
        <div className={styles.Signuptext}>
          <p>Sign Up</p>
        </div>
        {invalid && <div>
          <p className={styles.invalidStatus}>{invalid}</p>
        </div>}

        <div className={styles.input_group}>
          <label>
            <p>Email</p>
          </label>
          <input
            type='text'
            placeholder=''
            className={styles.input_field}
            onChange={signupEmail}
            onBlur={clearErrorMessages}
          />
        </div>

        <div className={styles.mob_business}>
          <div>
            <label>
              <p>Business Name</p>
            </label>
            <input
              type='text'
              placeholder=''
              className={styles.input_field}
              onChange={signupBusiness}
              onBlur={clearErrorMessages}
            />
          </div>
          <div className={` ${styles.mobile_input}`}>
            <label>
              <p>Mobile Number</p>
            </label>
            <input
              type='text'
              placeholder=''
              className={styles.input_field}
              onChange={signupNumber}
              onBlur={clearErrorMessages}
            />
          </div>
        </div>

        <div className={styles.mob_business}>
          <div className={styles.input_group}>
            <label>
              <p>Password</p>
            </label>
            <input
              type='password'
              placeholder=''
              className={styles.input_field}
              onChange={signupPassword}
              onBlur={clearErrorMessages}
            />
          </div>

          <div className={styles.input_group}>
            <label>
              <p>Confirm Password</p>
            </label>
            <input
              type='password'
              placeholder=''
              className={styles.input_field}
              onChange={signupConfirmPassword}
              onBlur={clearErrorMessages}
            />
          </div>
        </div>

        <button className={styles.Signup_button} onClick={signupFun}>Sign Up</button>

        <div className={styles.signupOption}>
          <Line content='or signup with' />
        </div>
        <div>
          <GoogleAuth />
        </div>
        <div className={styles.newuser}>
          <p>Already a User?</p>
          <Link to='/login'>Login</Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
