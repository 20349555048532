import React from 'react';
import styles from './Design.module.css';
// import wed from '../img/Wed/lap.jpg';
import wed from '../img/Wed/mockup_lap.png';
import mob from '../img/Wed/responsive_mockup.png';
// import mob from '../img/Wed/croped.png';

function Design() {
  return (
    <>
      <div className={styles.album_Head}>
        <div className={styles.album_web}>
          <img src={wed} alt='lap-mockup' />
        </div>
        <div className={styles.album_mob}>
          <img src={mob} alt='mob-mockup' />
        </div>
      </div>
      <div className={styles.album_layout}>
        <div className={styles.head_layout}>
          <p>Haldi images</p>
          <p>Wedding images</p>
        </div>
        <div className={styles.horizontal_line}></div>
        <div className={styles.gallary_layout}>
          <div className={styles.one} ></div>
          <div className={styles.two} ></div>
          <div className={styles.three} ></div>
          <div className={styles.four} ></div>
          <div className={styles.five} ></div>
          <div className={styles.six} ></div>
          <div className={styles.seven} ></div>
          <div className={styles.eight} ></div>
          <div className={styles.nine} ></div>
        </div>
      </div>
    </>
  );
}

export default Design;
