import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './Progress.css';
import { IoImageOutline } from "react-icons/io5";
import { SiTicktick } from "react-icons/si";

const formatFileName = (fileName) => {
  const parts = fileName.split('.');
  const extension = parts.pop(); 
  const baseName = parts.join('.'); 
  const trimmedBaseName = baseName.split('_')[0]; 
  return `${trimmedBaseName}.${extension}`;
};

const Progress = () => {
  const uploads = useSelector(state => state.user.uploads);
  const progressRef = useRef(null);

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.scrollTop = progressRef.current.scrollHeight;
    }
  }, [uploads]);

  return (
    <div className="progress-container" ref={progressRef}>
      <div className='Progressing'>
        {Object.entries(uploads).map(([fileName, { progress, status }]) => (
          <div className='EachTask' key={fileName}>
            <div className='Progressed'>
              <div className='imageIcon'>
                <span><IoImageOutline /></span>
              </div>
              <div className='FileName'>
                <p>{formatFileName(fileName)}</p>
              </div>
              {progress === 100 && (
                <div className='Progress-Status'>
                  <SiTicktick />
                </div>
              )}
            </div>
            <div className="progress-bar">
              <div
                className="progress-bar-inner"
                style={{
                  width: `${progress}%`,
                  backgroundColor: progress < 100 ? '#1f45ed' : '#1f45ed',
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Progress;
