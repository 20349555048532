import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import './DatePicker.css';

function formatCurrentDateTime() {
  const now = new Date();

  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const amOrPm = now.getHours() >= 12 ? 'PM' : 'AM';

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formattedDateTime;
}

export default function ResponsiveDateTimePickers({ Ondate }) {
  const currentTimestamp = Date.now();
  const [selectedDateTime, setSelectedDateTime] = React.useState(dayjs(formatCurrentDateTime()));

  const handleDateTimeChange = (newDateTime) => {
    setSelectedDateTime(newDateTime);
    Ondate(newDateTime); 
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MobileDateTimePicker']}>
        <DemoItem label="Date and Time">
          <MobileDateTimePicker
            value={selectedDateTime}
            onChange={handleDateTimeChange}
            className="custom-mobile-datetime-picker"
            sx={{ backgroundColor: 'white' }} 
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
} 
     