import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemIcon from '@mui/material/ListItemIcon';
import SendIcon from '@mui/icons-material/Send';

import './SelectBox.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

const names = [
  'Select the Event',
  'Wedding',
  'Birthday',
  'Corporate',
  'Fashion model photoshoot',
  'product photoshoot',
  'Real-estate photoshoot',
  'Restaurant photoshoot',
  'Theme photoshoot',
  'Couple Shoot',
  'Family Photoshoot',
  'Maternity Photoshoot',
  'New born baby photoshoot',
  'Engagement',
  'Others',    
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

export default function SingleSelect(props) {
  const theme = useTheme();
  const [personName, setPersonName] = useState('Select the Event');
  const [open, setOpen] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (props.onOptionSelect) {
      props.onOptionSelect(value);
    }

    // Check if the selected option requires additional fields
    if (value === 'Wedding' || value === 'Engagement' || value === 'Couple Shoot') {
      setShowAdditionalFields(true);
    } else {
      setShowAdditionalFields(false);
    }

    setPersonName(value);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Sort names alphabetically
  const sortedNames = names.slice().sort((a, b) => {
    if (a === 'Select the Event') {
      return -1;
    } else if (b === 'Select the Event') {
      return 1;
    } else if (a === 'Others') {
      return 1;
    } else if (b === 'Others') {
      return -1;
    } else {
      return a.localeCompare(b);
    }
  });

  return (
    <div>
      <FormControl sx={{ m: 1, width: 350,backgroundColor: 'white' }}>
        <Select
          labelId="demo-single-name-label"
          id="demo-single-name"
          value={personName}
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          open={open}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          {sortedNames.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}