import React from 'react'
import styles from './UserProfile.module.css';
import photo2 from '../img/pexels-stefan-stefancik-91227.jpg';
import { MdOutlineEmail } from "react-icons/md";
import { CiPhone } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { TiSocialFacebook } from "react-icons/ti";
import { FaInstagram } from "react-icons/fa";
import { CiLink } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import photo3 from '../img/user.png'; 
import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import logtail from '../logtail';

function UserProfile() {
    
    const navigate = useNavigate();
    const userStatus = useSelector(state => state.user.me);
    console.log(userStatus);

    const Logout =()=>{
        axios.get('https://api.hapzea.com/api/v1/user/logout',
        {withCredentials: true}
        )
        .then(response => {
            console.log('Logout successful');
            navigate('/login');
        })
        .catch(error => {
            console.error('Error logging out:', error);
        });  
    }

    return (
        <div className={styles.userProfile}>
            <div className={styles.container}>
                <div className={styles.backD}>
                {/* <div className={styles.edit}><FaRegEdit /></div> */}
                    <div className={styles.backDContent}>
                        <div className={styles.ProfilePhoto}>
                            <div className={styles.profilePic}>
                                <img src={photo3} alt='' />
                            </div>
                            <p>{userStatus.businessName}</p>
                            <ul>
                                <li><a href={userStatus.socialProfiles.facebook}><TiSocialFacebook className={styles.profileIcon}/></a></li>
                                <li><a href={userStatus.socialProfiles.instagram}><FaInstagram className={styles.profileIcon}/></a></li>
                                <li><a href={userStatus.website}><CiLink className={styles.profileIcon}/></a></li>
                                <li><a href={userStatus.socialProfiles.youtube}><CiYoutube className={styles.profileIcon}/></a></li>
                                <li><a href='#'><CiLinkedin className={styles.profileIcon}/></a></li>
                            </ul>
                        </div>
                        <div className={styles.Contacts}>
                            {/* <p>Contacts</p> */}
                            <ul>
                                <li><span><MdOutlineEmail /></span>{userStatus.email}</li>
                                <li><span><CiPhone /></span>{userStatus.mobile}</li>
                                <li><span><CiLocationOn /></span>{userStatus.address}</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.Logout} onClick={Logout}>Log Out</div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile