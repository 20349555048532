// import React from 'react'
// import styles from './Financials.module.css';
// import Linechart from './Linechart';


// function Financials() {
//   return (
//     <>
//     <div className={styles.financeOuter}>
//       <div className={styles.expense_card}>
//       <h3>Total revenue</h3>
//       <p><span>&#8377;</span>1200000</p>
//     </div>

//     <div className={styles.expense_card} >
//       <h3>Total expense</h3>
//       <p><span>&#8377;</span>1200000</p>
//     </div>

//     <div className={styles.expense_card}>
//       <h3>Total profit</h3>
//       <p><span>&#8377;</span>1200000</p>
//     </div>
//     </div>
//     <div className={styles.graph}>
//     <p>Analysis</p>
//     <Linechart/>
//     </div>
//     </>
//   )
// }

// export default Financials



import React from "react";
import styles from './Status.module.css';    
import { IoLockClosed } from "react-icons/io5";


function Status() {
  return (
    <div className={styles.status}>
      <div className={styles.container}>
        <div>
        <IoLockClosed className={styles.lock}/>
        <h1>Coming Soon...</h1>
        </div>
      </div>
    </div>
  );
} 

export default Status;