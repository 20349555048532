import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './Selected.module.css';
import folder from '../img/folder.svg';
import { NavLink, useParams, useLocation } from 'react-router-dom';


function Selected() {
  const { id } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    const shareMedia = async (id) => {
      if (id !== undefined) {
        try {
          const response = await axios.get(`https://api.hapzea.com/api/v1/user/metacheck/${id}`, {
            withCredentials: true,
          });
          const trimmedFolderPaths = response.data.data.map(path => path.replace(/\//g, ''));
          setAlbums(trimmedFolderPaths);
        } catch (error) {
          console.error('Error sharing media:', error);
        }
      }
    };
    shareMedia(id);
  }, [id]);

  return (
    <div>
      <div className={styles.folders}>
        {albums.map((folderPath, i) => (
          <div className={styles.each_folders} key={i}>
            <NavLink to={`${pathname}/${folderPath}`}className={styles.folder_link}>
              <span><img src={folder} alt="Folder Icon" /></span> {folderPath}
            </NavLink>
            {/* <p className={styles.selected_count}>0 Photo Selected</p> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Selected;