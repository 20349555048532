import React, { useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import './SelfiePage.css';

function SelfiePage() {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');

  const videoConstraints = {
    width: 300,
    height: 420,
    facingMode: "user"
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  }, [webcamRef]);

  const retake = () => {
    setImageSrc('');
  };

  const handleSubmit = () => {
    navigate('../confirm', { state: { imageSrc } });
};

  return (
    <div className="selfie-container">
      <div className='selfie-top'>
        <p>Adarsh & Unknown</p>
      </div>
      <h1>Upload Your Selfie</h1>
      {!imageSrc ? (
        <>
          <Webcam
            audio={false}
            height={420}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={300}
            videoConstraints={videoConstraints}
            mirrored={true}
          />
          <button className="capture-button" onClick={capture}></button>
        </>
      ) : (
        <>
          <img src={imageSrc} alt="Selfie" style={{ width: '80%', height: 'auto' }} className='capture_img'/>
          <button className="button retake" onClick={retake}>Retake</button>
          <button className="button submit" onClick={handleSubmit}>Next</button>
        </>
      )}
    </div>
  );
}

export default SelfiePage;
