import React from "react";
import styles from './Status.module.css';    
import { IoLockClosed } from "react-icons/io5";


function Status() {
  return (
    <div className={styles.status}>
      <div className={styles.container}>
        <div>
        <IoLockClosed className={styles.lock}/>
        <h1>Coming Soon...</h1>
        </div>
      </div>
    </div>
  );
} 

export default Status;