import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { IoLocationOutline } from "react-icons/io5";
import './CustomPage.css';
import logo from '../img/logo_test_white.png';

function CustomPage() {
  const params = useParams();

  const [permission, setPermission] = useState(false);
  const [client, setClient] = useState({});
  const [eventDate, setEventDate] = useState('');
  const [eventYear, setEventYear] = useState('');
  const [eventMonth, setEventMonth] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('https://images.indianexpress.com/2024/02/rakul-jackky-wedding.jpg?w=640');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const carrier = { businessName: params.businessName, EventName: params.EventName, id: params.id };
        const response = await axios.post('https://api.hapzea.com/api/v1/user/validatingLink', carrier, {
          withCredentials: true,
        });

        if (response.data.data.linkStatus === 'Allow Access') {
          setPermission(true);
          setClient(response.data.data.client);

          const timestamp = response.data.data.client.Date;
          const date = new Date(timestamp);
          const day = date.getDate();
          const month = date.toLocaleString('en-us', { month: 'long' });
          const year = date.getFullYear();
          setEventDate(day);
          setEventYear(year);
          setEventMonth(month);

          // Fetch the cover photo from backend
          const photoResponse = await axios.get(`https://api.hapzea.com/api/v1/user/getClientCoverPhoto?_id=${params.id}`, {
            responseType: 'blob', // Ensure response type is blob for image
            withCredentials: true,
          });

          const imageUrl = URL.createObjectURL(photoResponse.data);
          setBackgroundImage(imageUrl);
        } else {
          setPermission(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Fallback image if fetching fails
        setBackgroundImage('https://images.indianexpress.com/2024/02/rakul-jackky-wedding.jpg?w=640');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params.businessName, params.EventName, params.id]);

  const generateGoogleMapsLink = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  if (loading) {
    return <div className='loading'>Loading...</div>;
  }

  return (
    <div>
      {permission ? (
        <div className='container-invitation'>
          <div
            className='backpic'
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className='overlay'>
              {['Wedding', 'Couple Shoot', 'Engagement'].includes(client.EventCategory) ? (
                <>
                  <h3>{client.Bride} <span>&</span> {client.Groom}</h3>
                </>
              ) : (
                <h3>{client.ClientName}</h3>
              )}
            </div>
          </div>
          <div className='content'>
            <div className='date'>
              <h1 className='date-day'>{eventDate}<span>{getOrdinalSuffix(eventDate)}</span></h1>
              <h3 className='date-month'>{eventMonth}</h3>
              <h3 className='date-year'>{eventYear}</h3>
            </div>
            <div className='location'>
              <h3 className='venue'>{client.Venue}</h3>
              <a className='get-directions' href={generateGoogleMapsLink(client.Venue)} target='_blank' rel='noopener noreferrer'>
                <IoLocationOutline className='icon' />
                <span>Get Directions</span>
              </a>
            </div>
            <div className='invitation'>
              <h2>Join us for the celebration of love</h2>
              <p>We are thrilled to invite you to our celebration. Your presence will make our day special. Please join us as we tie the knot and celebrate our love.</p>
            </div>
            {/* <div className='company-logo'>
              <img src={logo} alt='Company Logo' />
            </div> */}
          </div>
        </div>
      ) : (
        <div className='not-available'>Not Available for you</div>
      )}
    </div>
  );
}

export default CustomPage;
