import './App.css';
import Login from './componets/Login.js';
import React, { useEffect, useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import LoginPage from './pages/LoginPage.js';
import SignupPage from './pages/SignupPage';
import Home from './pages/Home';
import Service from './pages/Service';
import ProtectedRoute from './protectedRoute';
import Operation from './pages/Operations';
import Events from './pages/Events';
import UploadPage from './pages/UploadPage';
import Design from './pages/Design';
import Hidden from './pages/HiddenPhotos';
import Album from './pages/Album';
import Selected from './pages/Selected';
import CustomPage from './pages/CustomPage';
import MainGallary from './pages/Gallary/MainGallary';
import ClientGallary from './pages/ClientGallary';
import SubPage from './componets/SubPage';
import SelectView from './pages/SelectView';
import FaceRecognition from './pages/FaceRecognition';

// import ImageUpload_face 

import ImageUpload from './componets/FaceRecognition/ImageUpload_face';
import Gallery from './componets/FaceRecognition/Gallery_face';
import Guests from './componets/FaceRecognition/Guests_face';

import axios from 'axios';
import { UserActions } from '../src/Store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import EditProfile from './pages/EditProfile';
import AlbumPage from './pages/AlbumPage';
import AlbumSubPage from './pages/AlbumSubPage';
import { IoCloseSharp } from "react-icons/io5";
import Progress from './componets/progressModal/Progress';
import DownloadPage from './pages/DownloadPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Scanner from './pages/scanner/Scanner';
import FormPage from './pages/scanner/FormPage';
import SelfiePage from './pages/scanner/SelfiePage';
import ConfirmPage from './pages/scanner/ConfirmPage';

function App() {
  const dispatch = useDispatch();
  const uploads = useSelector(state => state.user.uploads);
  const totalUploads = useSelector(state => state.user.totalUploads);
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({ current: 0, total: 0 });

  useEffect(() => {
    const userDetails = async () => {
      try {


        const response = await axios.get(
          'https://api.hapzea.com/api/v1/user/me',

          { withCredentials: true }
        );
        if (response) {
          console.log('FROM APP.JS');
          console.log(response.data.data);
          dispatch(UserActions.getMe(response.data.data.user));
          console.log('dispatched...........');
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    };
    userDetails();
  }, [dispatch]);

  useEffect(() => {
    console.log('Uploads state changed:', uploads);
    console.log('Total uploads:', totalUploads);

    const currentUploads = Object.keys(uploads).length;
    const totalUploadCount = (totalUploads && totalUploads.Total) || 0;

    console.log('Current uploads:', currentUploads);
    console.log('Total upload count:', totalUploadCount);

    setUploadProgress(prev => ({
      current: currentUploads,
      total: totalUploadCount,
    }));

    const shouldShowQueue = currentUploads > 0 || totalUploadCount > 0;
    console.log('Should show queue:', shouldShowQueue);
    setUploadTrigger(shouldShowQueue);

    // Automatically close the "Processing Queue" when upload is complete
    if (currentUploads === totalUploadCount && totalUploadCount > 0) {
      console.log('All uploads completed, closing the queue automatically...');
      setTimeout(() => {
        emptyUpload(); // Close the queue after a slight delay to avoid immediate reopening
      }, 500); // 500ms delay to ensure state updates are fully processed
    }

  }, [uploads, totalUploads]);


  const emptyUpload = () => {
    console.log('Emptying upload queue...');
    dispatch(UserActions.clearUploads());
    dispatch(UserActions.setTotalUploads({ Total: 0 }));
    setUploadTrigger(false);
    setUploadProgress({ current: 0, total: 0 });
  };

  console.log('Render - Upload trigger:', uploadTrigger);
  console.log('Render - Upload progress:', uploadProgress);


  return (
    <div className="App">
      {uploadTrigger && (
        <>
          <div className='closeBtnProgress'>
            <p>Processing Queue
              <span className='filesQueue'>
                <p>{`Uploading ${uploadProgress.current} of ${uploadProgress.total} images`}</p>
              </span>
            </p>
            <span onClick={emptyUpload}>
              <IoCloseSharp />
            </span>
          </div>
          <div className="MainApp">
            <div className="Progress">
              <Progress />
            </div>
          </div>
        </>
      )}
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<Outlet />}>
            <Route element={<UploadPage />} path="/uploads/:clientName/:eventDate/:id" exact>
              <Route element={<Album />} path="/uploads/:clientName/:eventDate/:id/:medium/folders/:select" exact />
              <Route element={<Design />} path="/uploads/:clientName/:eventDate/:id/:medium/design" exact />
              <Route element={<Hidden />} path="/uploads/:clientName/:eventDate/:id/:medium/hidden_photos" exact />
              <Route element={<Selected />} path="/uploads/:clientName/:eventDate/:id/:medium/selectedPhotos" exact />
              <Route element={<SelectView />} path="/uploads/:clientName/:eventDate/:id/:medium/selectedPhotos/:select" exact />
            </Route>
            <Route element={<EditProfile />} path="/services/settings/:option" exact />
            <Route element={<Service />} path="/services" exact />FaceRecognition
            <Route element={<Operation />} path="/operations" exact />
            <Route element={<Events />} path="/events/:id" exact />
            <Route path="/:id/ai/face_recognition" element={<FaceRecognition />}>
              <Route path="upload" element={<ImageUpload />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="guests" element={<Guests />} />
            </Route>


            <Route element={<MainGallary />} path="/uploads/:clientName/:eventDate/:id/:medium/folders/:select/view_more" exact />
          </Route>
        </Route>
        <Route element={<Home />} path="/" exact />
        <Route element={<CustomPage />} path="/invitation/:businessName/:EventName/:id" exact />
        <Route element={<AlbumPage />} path="/album/:businessName/:EventName/:id" exact>
          <Route element={<AlbumSubPage />} path="/album/:businessName/:EventName/:id/:folderName" exact />
        </Route>
        <Route element={<ClientGallary />} path="/:businessName/:id/just" exact>
          <Route element={<SubPage />} path="/:businessName/:id/just/:folderName" exact />
        </Route>
        <Route element={<LoginPage />} path="/login" exact />
        <Route element={<SignupPage />} path="/signup" exact />
        <Route path="/:id/ai/face_recognition/scan-qr-selfie-upload" element={<Scanner />}>
              <Route index element={<FormPage />} />
              <Route path="selfie" element={<SelfiePage />} />
              <Route path="confirm" element={<ConfirmPage />} />
            </Route>
        <Route element={<DownloadPage />} path="/desktop_app/download" exact />
      </Routes>
    </div>
  );
}


export default App;
