import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './SelectView.module.css';
import { IoMdArrowBack } from "react-icons/io";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GoDownload } from "react-icons/go";
import { UserActions } from '../Store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LuExternalLink } from "react-icons/lu";

function SelectView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, select } = useParams();
    const [imagesState, setImagesState] = useState([]);

    useEffect(() => {
        const shareMedia = async (id, select) => {
            if (id !== undefined) {
                try {
                    const response = await axios.get(
                        `https://api.hapzea.com/api/v1/user/meta_selction_check/${id}`,
                        {
                            params: {
                                subFiles: select
                            },
                            withCredentials: true
                        }
                    );
                    
                    const folderNames = response.data.data.folderNames;
                    dispatch(UserActions.userSelectionResponse({ images: folderNames }));
                    console.log('@@@@@@@@');
                    console.log(folderNames);
                    const urls = folderNames.map(folderName => {
                        return `https://storage.cloud.google.com/hapzea/${id}/PhotoSelection/${select}/${folderName}`;
                    });
                    setImagesState(urls);
                } catch (error) {
                    console.error('Error sharing media:', error);
                    // Handle error if needed
                }
            }
        };
        shareMedia(id, select);
    }, [id, select]); 

    const handleDownloadFile = () => {
        const downloadUrl = '/Desktop_app/Hapzea Desktop SetupV1.0.exe';
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'Hapzea Desktop SetupV1.0.exe'; 
        document.body.appendChild(link); 
        link.click(); 
        document.body.removeChild(link); 
    };

    const handleGoBack = () => {
        navigate(-1); // Navigate back to previous page
    };

    return (
        <div>
            <div className={styles.backbtn} onClick={handleGoBack}>
                <IoMdArrowBack />
            </div>
            <div className={styles.Download_banner}>
                <div className={styles.Download_banner_outerlayer}>
                    <div className={styles.Download_banner_inside}>
                        <p>To download selected images in original resolution, use our easy-to-use desktop app for the best quality. Watch our video tutorial for help.<span><a target='_blank' href='https://www.youtube.com/'><LuExternalLink /></a></span></p>
                    </div>
                    <div>
                        <div className={styles.downloadbtn_desktop} onClick={handleDownloadFile}>
                            Download <span><GoDownload /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                {imagesState.map((slide, index) => (
                    <div key={index} className={styles.GalleryTile}>
                        <img src={slide} alt={`Image ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SelectView;
