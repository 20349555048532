import React from 'react';
import styled, { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0% {
    transform: translateX(-50%);
    opacity: 0.5;
  }
  50% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0.5;
  }
`;

const Button = styled.button`
  position: relative; /* Ensure positioning context for absolute positioning */
  width: 200px; /* Set a fixed width */
  padding: 10px 20px;
  font-size: 16px;
  background-color: ${(props) => (props.loading ? '#007bff80' : '#007bff')};
  // background-color: ${(props) => (props.loading ? '#82b9ba' : '#057475')};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.loading ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;
  opacity: ${(props) => (props.loading ? 0.7 : 1)};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const Wave = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
`;

const WaveDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff; /* Ensure contrast with button background */
  margin: 0 5px; /* Add margin for spacing */
  animation: ${waveAnimation} 1.5s ease infinite alternate;
`;

const SubmitButton = ({ children, loading, ...rest }) => {
  return (
    <Button disabled={loading} loading={loading} {...rest}>
      {loading ? (
        <Wave>
          <WaveDot />
          <WaveDot />
          <WaveDot />
        </Wave>
      ) : (
        children
      )}
    </Button>
  );
};

export default SubmitButton;
