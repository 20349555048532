import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './MediaClient.module.css';
import { AiOutlineLeft } from 'react-icons/ai';
import backBtn from '../img/backBtn.svg';
import selection from '../img/selection.svg';
import album from '../img/album.svg';
import location from '../img/location.svg';
import Calender from '../img/Calender.svg';
import userstar from '../img/userstar.svg';
import folder from '../img/folder.svg';
import checkmark from '../img/checkmark.svg';
import open from '../img/open.svg';
import link from '../img/link.svg';
import editPen from '../img/editPen.svg';
import gallary from '../img/gallary.svg';
import share from '../img/share.svg';
import { useNavigate } from 'react-router-dom';
import NewFolder from '../componets/NewFolder'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UserActions } from '../Store/userSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SharePublic from './ShareModal/SharePublic';
import uploadIcon from '../img/upload.svg';
import { IoCamera } from "react-icons/io5";
import { IoImageOutline } from "react-icons/io5";
import ShareAlbum from './ShareModal/ShareAlbumpage';


function MediaClient(props) {
  const { Onnames, toggleToFaceRecognition } = props;
  const { id } = useParams();
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.user);
  // const [folders, setFolders] = useState([]);
  const [showNewFolderInputSectionTwo, setShowNewFolderInputSectionTwo] = useState(false);
  const [newFolderNameSectionTwo, setNewFolderNameSectionTwo] = useState('');
  const [showNewFolderInputSectionThree, setShowNewFolderInputSectionThree] = useState(false);
  const [newFolderNameSectionThree, setNewFolderNameSectionThree] = useState('');
  const [Albums, setNewFolderAlbums] = useState([]);
  const [PhotoSelection, setNewFolderPhotoselection] = useState([]);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showShareAlbum, setShowShareAlbum] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [fetchCall, SetFetchCall] = useState(false);


  const navigate = useNavigate();
  // const { Onnames } = props;
  const { Groom, Bride, EventName, Time, ClientName, Date, Venue, magicLink, _id } = Onnames || {};



  const [eventData, setEventData] = useState({
    _id: '',
    userId: '',
    EventCategory: '',
    Groom: '',
    Bride: '',
    EventName: '',
    Venue: '',
    ClientName: '',
    Email: '',
    Date: '',
    Source: '',
    Phone: '',
    magicLink: '',
    __v: 0,
  });

  // const handleFileUpload = async (files) => {
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append('photos', files[i]);
  //   }
  //   try {
  //     const response = await axios.post('https://api.hapzea.com/api/v1/user/uploadClientCoverPhoto', formData, {
  //       params: { _id: id },
  //       headers: { 'Content-Type': 'multipart/form-data' },
  //       withCredentials: true,
  //     });
  //     console.log('File upload successful:', response.data);
  //     if (response.data.status === 'success') {
  //       console.log(response.data.status);
  //       console.log(response.data);
  //       SetFetchCall(!fetchCall);
  //     }
  //   } catch (error) {
  //     handleUploadError(error);
  //   }
  // };
  
  const handleFileUpload = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('photos', files[i]);
    }
    try {
      const response = await axios.post('https://api.hapzea.com/api/v1/user/uploadClientCoverPhoto', formData, {
        params: { _id: id },
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true,
      });
      console.log('File upload successful:', response.data);
      if (response.data.status === 'success') {
        console.log(response.data.status);
        console.log(response.data);
        // Fetch the latest image data after successful upload
        await fetchLatestImage();
        SetFetchCall(!fetchCall);
      }
    } catch (error) {
      handleUploadError(error);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    handleFileUpload(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    handleFileUpload(files);
  };

  const toggleNewFolderInputSectionThree = () => {
    setShowNewFolderInputSectionThree(!showNewFolderInputSectionThree);
  };

  const toggleNewFolderInputSectionTwo = () => {
    setShowNewFolderInputSectionTwo(!showNewFolderInputSectionTwo);
  };

  const clearError = () => {
    // Implement logic to clear errors
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.hapzea.com/api/v1/user/getFiles', {
        params: {
          _id: id
        },
        withCredentials: true,
      });
      const { album, photo } = response.data.data;
      console.log('^^^^^^^^^^^^^^^');
      console.log(response.data.data);
      dispatch(UserActions.firstfiles([album, photo]));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

 const fetchLatestImage = async (from) => {
  try {
    // Add a delay to account for any propagation delay
    if(!from){
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    const photoResponse = await axios.get(`https://api.hapzea.com/api/v1/user/getClientCoverPhoto?_id=${id}`, {
      responseType: 'blob',
      withCredentials: true,
    });
    const imageUrl = URL.createObjectURL(photoResponse.data);
    setBackgroundImage(imageUrl); // Update the state with the new image URL
  } catch (error) {
    console.error('Error fetching photo:', error);
  }
};

  useEffect(() => {
    const from ='use';
    fetchLatestImage(from);
  }, [id]);

  useEffect(() => {
    // const fetchPhoto = async () => {
    //   try {
    //     const photoResponse = await axios.get(`https://api.hapzea.com/api/v1/user/getClientCoverPhoto?_id=${id}`, {
    //       responseType: 'blob',
    //       withCredentials: true,
    //     });
    //     const imageUrl = URL.createObjectURL(photoResponse.data);
    //     console.log('$$$$');
    //     console.log(imageUrl);
    //     setBackgroundImage(imageUrl); 
    //   } catch (error) {
    //     console.error('Error fetching photo:', error);
    //   }
    // };


    // fetchPhoto();
    fetchData();
  }, [id]);


  /////////////////////////////////


  const sharecall =()=>{
    setShowSharePopup(!showSharePopup);
    console.log('call');
  }

  const cancelSharePopup = () => {
    setShowSharePopup(false)
  }


  async function createFolder(id, nameFolder, media) {
    try {
      const response = await axios.post(
        'https://api.hapzea.com/api/v1/user/createfolder',
        { folder_name: nameFolder, main_folder: media },
        { params: { _id: id }, withCredentials: true }
       );
  
      console.log('Folder creation successful:', response.data);
      fetchData();
    } catch (error) {
      console.error('Error creating folder:', error.message);
    }
  }

  useEffect(() => {
    const replaceUserAsync = async (clientData) => {
      return new Promise((resolve, reject) => {
        dispatch(UserActions.replaceUser(clientData));
        resolve();
      });
    };

    const fetchData = async () => {
      try {
        
        // console.log(id);
        if (id !== undefined) {
          const response = await axios.get(`https://api.hapzea.com/api/v1/user/client/${id}`, {
            withCredentials: true,
          });

          console.log('client using ID')
          console.log(response.data.data);
          await replaceUserAsync(response.data.data.client);
        }
      } catch (error) {

      }
    };
    fetchData(); 
  }, [_id]);

 
  useEffect(() => {
    // setNewFolderAlbums(userStatus.Album.subdirectoriesList)
    setNewFolderAlbums(userStatus.Album)
    // setNewFolderPhotoselection(userStatus.PhotoSelection.subdirectoriesList)
    setNewFolderPhotoselection(userStatus.PhotoSelection)
    setEventData(userStatus.user);
  }, [userStatus]);

  const handleNewFolderSubmitSectionTwo = async () => {
    if (newFolderNameSectionTwo) {
      await createFolder(id, newFolderNameSectionTwo, 'PhotoSelection');
    }
    setNewFolderNameSectionTwo('');
    setShowNewFolderInputSectionTwo(false);
  };

  const handleNewFolderSubmitSectionThree = async () => {
    if (newFolderNameSectionThree) {
      await createFolder(id, newFolderNameSectionThree, 'Album');
    }
    setNewFolderNameSectionThree('');
    setShowNewFolderInputSectionThree(false);
  };

  const handleNewFolderNameChangeSectionTwo = (event) => {
    setNewFolderNameSectionTwo(event.target.value);
  };

  const handleNewFolderNameChangeSectionThree = (event) => {
    setNewFolderNameSectionThree(event.target.value);
  };

  const GoBack = () => {
    navigate('/services', {
      state: {
        team: false, home: false, project: true,
        help: false,
        status: false,
        setting: false,
        finance: false,
        active: 'project',
        hover: false
      },
    });
  }

  //Going to Albim.js
  const goToUploads = (item) => {
    const select = typeof item === 'object' ? JSON.stringify(item) : item;
      dispatch(UserActions.updateSelected({ parent: 'PhotoSelection', selection: `${select}` }));
      // console.log(`URL ${folderName}`);
      navigate(`/uploads/${eventData.ClientName}/${eventData.Date}/${id}/choose/folders/${select}`);
  };
  
  //Going to Albim.js
  const goToUploadsAlbums = (item) => {
    const select = typeof item === 'object' ? JSON.stringify(item) : item;
      dispatch(UserActions.updateSelected({ parent: 'Album', selection: `${select}` }));
      navigate(`/uploads/${eventData.ClientName}/${eventData.Date}/${id}/set/folders/${select}`);  
  };

  const handleUploadError = (error) => {
    if (error.response) {
      console.error('Error response:', error.response.data);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    console.error('Error uploading file:', error.config);
  };
  
////  nived 

  // Toggle function for ShareAlbum component
  const toggleShareAlbum = () => {
    setShowShareAlbum(!showShareAlbum);
  };


  const toggletoai = () => {
    navigate(`/${id}/ai/face_recognition/upload`, {
      state: { eventData }
    });
  };
  

   
  return (
    <>
      <div className={styles.clientTitle}>
        <div className={styles.topClients}><span onClick={GoBack}>
          <img src={backBtn} />
        </span> {eventData.ClientName}</div>
      </div>
      <div className={styles.PhotoSelect}>
        <div className={styles.leftSection}>
          <div className={styles.sectionOne}>
            <div className={styles.LefoffLeft}>
              <div className={styles.publicPage}>
                <Link to={`${eventData.magicLink}`} target="_blank">
                  <p>public page link<span><img src={link} alt="Link Icon" /></span></p>
                </Link>
                <h3>{eventData.ClientName}</h3>
              </div>
              <div className={styles.editPages}>
                <p>EDIT PUBLIC PAGE<span><img src={editPen} /></span></p>
              </div>
            </div>
            <div className={styles.CenteroffLeft}>
            
              <div className={styles.centerImg}
                style={{ backgroundImage: `url(${backgroundImage})` }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {backgroundImage ? (
                  <>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="fileUpload"
                      onChange={handleFileChange}
                      multiple
                    />
                    <label htmlFor="fileUpload" className={styles.fileUploadLabels}>
                      <div className={styles.overlay}>
                        <IoImageOutline className={styles.cameraIcon} />
                        <span className={styles.uploadText}>Upload Cover Photo</span>
                      </div>
                    </label>
                  </>
                ) : (
                  <>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="fileUpload"
                      onChange={handleFileChange}
                      multiple
                    />
                    <label htmlFor="fileUpload" className={styles.fileUploadLabel}>
                      <img className={styles.uploadIcon} src={uploadIcon} alt="Upload Icon" />
                    </label>
                  </>
                )}
             </div>

            </div>
            <div className={styles.RightoffLeft}>
              <div className={styles.shareBtn} onClick={sharecall}>
                <p>SHARE<span><img src={share} /></span></p>
              </div>
            </div>
          </div>
          <div className={styles.sectionThree}>
            <div className={styles.photoHead}>
              <div className={styles.contentHead}>
                <span>
                  <img src={album} />
                </span>
                <p>Album</p>
              </div>
            </div>
            <div className={styles.photoBody}>
              <ul>
                {/* {folders.map((folder, index) => (
            <li key={index}>New folder</li>
          ))} */}
                {Array.isArray(Albums) && Albums.map((item, i) => (
                  <li key={i} onClick={() => goToUploadsAlbums(item)}>
                    <Link>
                      <span>
                        <img src={folder} alt="Folder Icon" />
                      </span>
                      {item}
                    </Link>
                  </li>
                ))}

              </ul>
            </div>
            <div className={styles.addFolder}>
              <p onClick={toggleNewFolderInputSectionThree}><span>+</span>Folder</p>
              <p>Choose from Albums</p>
            </div>
            {/* //  Add Folder ***********  */}

            {showNewFolderInputSectionThree && (
              <>
                <div className={styles.newFolderInputBox}>
                  <div className={styles.title}>
                    <p>Add Folder to the Album</p>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter new folder name"
                    value={newFolderNameSectionThree}
                    onChange={handleNewFolderNameChangeSectionThree}
                  />
                  <div className={styles.buttonArray}>
                    <button className={styles.folderSbt} onClick={handleNewFolderSubmitSectionThree}>Cancel</button>
                    <button className={styles.folderSbt} onClick={handleNewFolderSubmitSectionThree}>Create Folder</button>
                  </div>
                </div>
              </>
            )}
            {/* <div className={styles.horizontal_line}></div> */}
          </div>
          <div className={styles.sectionTwo}>
            <div className={styles.photoHead}>
              <div className={styles.contentHead}>
                <span>
                  <img src={selection} />
                </span>
                <p>Photo Selection</p>
              </div>
            </div>
            <div className={styles.photoBody}>
              <ul>
                {/* {folders.map((folder, index) => (
            <li key={index}>New folder</li>
          ))} */}
                {Array.isArray(PhotoSelection) && PhotoSelection.map((item, i) => (
                  <li key={i} onClick={() => goToUploads(item)}>
                    <Link>
                      <span>
                        <img src={folder} alt="Folder Icon" />
                      </span>
                      {item}
                    </Link>
                  </li>
                ))}
                {/* <li><span><img src={folder}/></span> New folder</li> */}
              </ul>
            </div>
            <div className={styles.addFolder}>
              <p onClick={toggleNewFolderInputSectionTwo}><span>+</span>Folder</p>
              <p>Choose from Albums</p>
            </div>
            {/* //  Add Folder ***********  */}
            {showNewFolderInputSectionTwo && (
              <>
                <div className={styles.newFolderInputBox}>
                  <div className={styles.title}>
                    <p>Add Folder to the Photo Selection</p>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter new folder name"
                    value={newFolderNameSectionTwo}
                    onChange={handleNewFolderNameChangeSectionTwo}
                  />
                  <div className={styles.buttonArray}>
                    <button className={styles.folderSbt} onClick={handleNewFolderSubmitSectionTwo}>Cancel</button>
                    <button className={styles.folderSbt} onClick={handleNewFolderSubmitSectionTwo}>Create Folder</button>
                  </div>
                </div>
              </>
            )}

            <div className={styles.horizontal_line}></div>
            <div className={styles.bottomFolder}>
              <p><span><img src={open} /></span>Open</p>
              <p><span><img src={checkmark} /></span> Selected Photos</p>
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
  <div className={styles.rightSectionOne}>
    <div className={styles.Contents}>
      <p>Details</p>
      <div className={styles.ContentsInside}>
        <p><span><img src={location} /></span>{`${eventData.Venue}`}</p>
        <p><span><img src={Calender} /></span>{`${eventData.Date}`} in 3 days </p>
        {/* <p><span><img src={userstar} /></span>{`${eventData.ClientName}`}</p>
        <p><span></span>Team</p>
        <p><span></span></p> */}
        <div className={styles.financialsBtn} onClick={toggleShareAlbum}>
          Share
        </div>
      </div>
    </div>
  </div>

  <div className={styles.rightSectionOne}>
    <div className={styles.Contents}>
      <p>Face Recognition</p>
      <div className={styles.ContentsInside}>
        <p>Engage with your clients by sharing event photos via Face Recognition</p>
        {/* <p><span><img src={location} /></span>{`${eventData.Venue}`}</p>
        <p><span><img src={Calender} /></span>{`${eventData.Date}`} in 3 days </p> */}
      
        <div className={styles.financialsBtn} onClick={toggletoai}>
          Go
        </div>
      </div>
    </div>
  </div>

</div>

{showShareAlbum && (
            <ShareAlbum
            onCancel={toggleShareAlbum}
            clearError={clearError}
          />
)}
      </div>

      {showSharePopup && (
        <div>
          <div >
            <SharePublic
              onCancel={cancelSharePopup}
              clearError={clearError}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default MediaClient;

