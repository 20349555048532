import React, { useState } from 'react';
import styles from './UploadPage.module.css'
import SideTab from '../componets/SideTab'
import Albums from '../componets/Albums';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PhotoSelect from '../componets/PhotoSelect';


function UploadPage(props) {
  const { clientName, eventDate, medium,select,id } = useParams();
  
  const location = useLocation();
  const state = { clientName, eventDate, medium,select,id }
  // const albumState = location.state?.albumState || null;

  console.log('***********');
  console.log(location);
  console.log('From uploads');


  const [activeTab, setActiveTab] = useState('project');


  return (
    <div className={styles.uploads}>
      <SideTab activeTab={activeTab} />
      <div className={styles.AlbumCover}>
        <Albums OnClient={state} />
      </div>
    </div>
  );
}

export default UploadPage;
