import React from 'react'

function HiddenPhotos() {
  return (
    <>
    <div></div>
    <div style={{ paddingTop: '20px' }}>HiddenPhotos</div>
    </>
      )
}

export default HiddenPhotos