import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import Card from './Card';
import axios from 'axios';
import classes from './SharePublic.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Backdrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onCancel} />;
};

const ModalOverlay = (props) => {
    const userStatus = useSelector(state => state.user);
    const [Email, setEmail] = useState('');
    const [isSending, setIsSending] = useState(false);
    const notify1 = () => toast.success('🦄 Invitation sent successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",

        });
    const notifyfalse1 = () => toast.error('Failed to send invitation.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",

        });
        

    useEffect(() => {
        setEmail(userStatus.user.Email || '');
    }, [userStatus.user.Email]);

    const SubmitSend = async () => {
        setIsSending(true);
        console.log(Email);
        try {
            const { user } = userStatus;
            const Magic = user.magicLink;
            const parts = Magic.split('/');
            const dynamicValue = parts[4];
        
            const requestBody = {
                magic_url: `https://hapzea.com/invitation/${dynamicValue}/${user.EventName}/${user._id}`,
                email: Email,
                company_name: dynamicValue,
                event_name: user.EventName
            };
        
            const response = await axios.post('https://api.hapzea.com/api/v1/user/sendUrl', requestBody, { withCredentials: true });
            console.log('POST request successful:', response.data);
            notify1()
        } catch (error) {
            notifyfalse1()
            console.error('Error sending POST request:', error);
        }
      
        props.onCancel();
    };
    
    const handleInputFocus = () => {
        props.clearError();
    };

    return (
        <Card className={classes.modal}>
            <div className={classes.container}>
                <div className={classes.title}>
                    <p>Share Public link</p>
                </div>
                <div className={classes.text}>
                    <p>Your client's email address has been automatically populated. Simply review and confirm before sending:</p>
                </div>

                <div class={classes.email_form_container} >
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className={classes.email_input}
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={handleInputFocus}
                    />
                    <button className={classes.send_button} onClick={SubmitSend} disabled={isSending}>
                        {isSending ? 'Sending...' : 'Send Link'}
                    </button>
                </div>

                <div className={classes.terms_text}>
                    <p>By clicking "Send Link," you agree to our <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>.</p>
                </div>

            </div>
        </Card>
    );
};

const SharePublic = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onCancel={props.onCancel} />,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay
                onCancel={props.onCancel}
                    clearError={props.clearError} // Pass clearError prop
                />,
                document.getElementById('overlay-root')
            )}
        </React.Fragment>
    );
};


export default SharePublic;