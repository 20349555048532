import React, { useEffect } from 'react';
import styles from './ProjectPage.module.css';
import SearchBar from '../componets/SearchBar';
import ClientList from '../componets/ClientList';



function ProjectPage(props) {
  const addingClients =()=>{
     props.Onadd();
  }

  const searchBarWidth = '760px';

  useEffect(() => {
      window.scrollTo(0, 0); 
      console.log('done');
  },[]);


  return (
    <>
    <div className={styles.topProject}>
        {/* <h2>Projects</h2> */}
        <SearchBar width={searchBarWidth}/>
        <div className={styles.addbtn}>
          <button className={styles.addclient} onClick={addingClients}>Add Client</button>
        </div>
    </div>
    {/* <SearchBar/> */}
    <div className={styles.horizontal_line }></div>
    <div className={styles.listProjects}>
      <ClientList/>
    </div>
    </>
  )
}   

export default ProjectPage