import React, { useState, useRef } from 'react';
import './FaceRecognition.css';
import { useLocation, useNavigate, NavLink, Outlet } from 'react-router-dom';
import backBtn from '../img/backBtn.svg';
import { QRCodeCanvas } from 'qrcode.react'; // Updated import

export default function FaceRecognition() {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventData } = location.state || {};
  const [showQRCode, setShowQRCode] = useState(false);
  const [publishDisabled, setPublishDisabled] = useState(false); // State to disable publish button
  const qrRef = useRef(null);

  const GoBack = () => {
    navigate('/services', {
      state: {
        team: false,
        home: false,
        project: true,
        help: false,
        status: false,
        setting: false,
        finance: false,
        active: 'project',
        hover: false,
      },
    });
  };

  const handlePublish = () => {
    if (!eventData) {
      alert('No event data available to generate QR Code.');
      return;
    }
    setShowQRCode(true);
    setPublishDisabled(true); // Disable the publish button after publishing
  };

  const handleCloseEvent = () => {
    const confirmClose = window.confirm('Are you sure you want to close this event?');
    if (confirmClose) {
      // Implement your close event logic here
      alert('Event Closed');
      // Example: Navigate to the home page
      navigate('/home');
    }
  };

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = `${eventData?.ClientName || 'qr_code'}.png`;
      a.click();
    }
  };

  // Generate a URL instead of encoding entire eventData
  const eventURL = eventData ? `https://yourdomain.com/events/${eventData.id}` : 'https://yourdomain.com';

  return (
    <div className='face_reco_container'>
      <div className='face_reco_layer'>
        <div className='face_reco_sidebar'>
          {/* Sidebar content */}
        </div>
        <div className='face_reco_main'>
          <div className='clientTitle'>
            <div className='topClients'>
              <span onClick={GoBack}>
                <img src={backBtn} alt="Back" />
              </span>
              {eventData?.ClientName}
            </div>
          </div>

          {/* Navbar with styled NavLink */}
          <div className="navbar">
            <NavLink 
              to="upload" 
              state={{ eventData }}  // Pass eventData in state
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Image Upload
            </NavLink>
            <NavLink 
              to="gallery" 
              state={{ eventData }}  // Pass eventData in state
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Gallery
            </NavLink>
            <NavLink 
              to="guests" 
              state={{ eventData }}  // Pass eventData in state
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Guests
            </NavLink>
          </div>

          {/* Pass the eventData via Outlet context */}
          <div className="content-container">
            <Outlet context={{ eventData }} />
          </div>
        </div>

        <div className='face_reco_rightbar'>
          <button 
            onClick={handlePublish} 
            className='publish-button' 
            aria-label="Publish Event"
            disabled={publishDisabled} // Disable button based on state
          >
            Publish
          </button>

          {showQRCode && (
            <div className="qr-code-container" ref={qrRef} onClick={downloadQRCode}>
              <QRCodeCanvas 
                value={eventURL} // Using eventURL instead of full eventData
                size={150} 
                includeMargin={true}
              />
              <p className="qr-code-text">Click QR Code to Download</p>
            </div>
          )}

          <button 
            onClick={handleCloseEvent} 
            className='close-event-button' 
            aria-label="Close Event"
          >
            Close Event
          </button>
        </div>
      </div>
    </div>
  );
}
