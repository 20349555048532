import React from 'react'
import { useEffect } from 'react';
import Header from '../componets/Header'
import styles from './Home.module.css'
import { Link } from 'react-router-dom';
import Select from 'react-select';
import flow from '../img/hero_flow.png'
import app from '../img/demo_gif2.gif'
import digital_album from '../img/DigitalAlbum.png'
import Digitalinvite from '../img/Digitalinvite.png'
import selection from '../img/selection.png'
// import logo from '../img/logo_test.png';
import logo from '../img/logo_test_white.png';
import { SlSocialFacebook } from "react-icons/sl";
import { RiTwitterXLine } from "react-icons/ri";
import { SlSocialInstagram } from "react-icons/sl";
import { SlSocialLinkedin } from "react-icons/sl";



function Home() {

  useEffect(() => {
    let aboutShapeObserver;
    let appElementObserver;
    let imagesTwoObserver;
    let descriptionObserver;
    let imagesOneObserver;

    const aboutShape = document.querySelector(`.${styles.aboutShape}`);
    const appElement = document.querySelector(`.${styles.app}`);
    const imagesTwo = document.querySelector(`.${styles.imagesTwo}`);
    const description = document.querySelector(`.${styles.description}`);
    const imagesOne = document.querySelector(`.${styles.imagesOne}`);

    const options = {
      threshold: 0.5 // Trigger intersection when at least 50% of the element is visible
    };

    const animateElement = (element) => {
      element.classList.add(styles.animate);
    };

    const observeElement = (element, observer) => {
      observer.observe(element);
    };

    if (aboutShape) {
      aboutShapeObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateElement(entry.target);
            aboutShapeObserver.unobserve(entry.target);
          }
        });
      }, options);

      observeElement(aboutShape, aboutShapeObserver);
    }

    if (appElement) {
      appElementObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateElement(entry.target);
            appElementObserver.unobserve(entry.target);
          }
        });
      }, options);

      observeElement(appElement, appElementObserver);
    }

    if (imagesTwo) {
      imagesTwoObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateElement(entry.target);
            imagesTwoObserver.unobserve(entry.target);
          }
        });
      }, options);

      observeElement(imagesTwo, imagesTwoObserver);
    }

    if (description) {
      descriptionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateElement(entry.target);
            descriptionObserver.unobserve(entry.target);
          }
        });
      }, options);

      observeElement(description, descriptionObserver);
    }

    if (imagesOne) {
      imagesOneObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateElement(entry.target);
            imagesOneObserver.unobserve(entry.target);
          }
        });
      }, options);

      observeElement(imagesOne, imagesOneObserver);
    }

    return () => {
      if (aboutShapeObserver) {
        aboutShapeObserver.disconnect();
      }
      if (appElementObserver) {
        appElementObserver.disconnect();
      }
      if (imagesTwoObserver) {
        imagesTwoObserver.disconnect();
      }
      if (descriptionObserver) {
        descriptionObserver.disconnect();
      }
      if (imagesOneObserver) {
        imagesOneObserver.disconnect();
      }
    };
  }, []);
  return (
    <div className={styles.homie}>
      <Header />
      <div className={styles.hero_page}>
        <section>
          <div className={styles.content}>
            <div className={styles.heroText}>
              <h2>Click, Select, Deliver <span>Photography workflow made effortless</span></h2>
              <div className={styles.signupbox}>
                <div className={styles.signBtn}><li><a href='https://hapzea.com/signup'>Sign up for free</a></li></div>
              </div>
            </div>
            <div className={styles.heroImg}>
              <img src={flow} alt='flow' />
            </div>
          </div>
          <div className={styles.hero_section}>
            <div className={`${styles.shape} ${styles.shape1}`}></div>
            <div className={`${styles.shape} ${styles.shape2}`}></div>
            <div className={`${styles.shape} ${styles.shape3}`}></div>
            <div className={`${styles.shape} ${styles.shape4}`}></div>
            <div className={`${styles.shape} ${styles.shape5}`}></div>
            <div className={`${styles.shape} ${styles.shape6}`}></div>
          </div>
        </section>
        <section className={styles.brief}>
          <div className={styles.title}>Why Hapzea ?</div>
          <p>End to End Workflow management.
            Our platform streamlines the entire workflow from click to delivery,
            making every step effortless. From sending digital invites to delivering the final album,
            our intuitive solutions simplify every task. Upload, share, select,
            edit, and deliver—the process couldn't be simpler.</p>
        </section>
        <section className={styles.projection}>
          <div className={styles.about_image}>
            <div className={styles.aboutShape}></div>
            <img className={styles.app} src={app} alt="app" />
          </div>
        </section>
        <section className={styles.features}>
          <div>
            <div className={styles.images}>
              <img className={styles.digital_album} src={digital_album} alt="digital_album" />
            </div>
          </div>
          <div className={styles.description}>
            <h2>Digital Album</h2>
            <p>Easily share special moments with your clients using our easy-to-use platform</p>
          </div>
        </section>
        <section className={styles.featuresOne}>
          <div className={styles.description}>
            <h2>Digital Invites</h2>
            <p>Enhance your clients' events with digital invites, a contemporary approach to inviting guests. Assist your clients in creating unforgettable moments and spreading joy to loved ones, ensuring their celebrations are truly memorable.</p>
          </div>
          <div>
            <div className={styles.imagesOne}>
              <img className={styles.digital_album} src={Digitalinvite} alt="digital_album" />
            </div>
          </div>
        </section>
        <section className={`${styles.features} ${styles.featuresTwo}`}>
          <div>

            <div className={styles.imagesTwo}>
              <img className={styles.digital_album} src={selection} alt="digital_album" />
            </div>
          </div>
          <div className={styles.description}>
            <h2>Photo Selection</h2>
            <p>Empower your clients with the flexibility to access and select photos for their album.</p>
          </div>
        </section>
        <footer className={styles.footer}>
          <div className={styles.footerContainer}>
            <div className={styles.footerDetail}>
              <div className={styles.brand}>
                <div className={styles.logo}>
                  <li><a href="#home"><img className='logo_img' src={logo} alt="Logo" /></a></li>
                </div>
                <p>Hapzea is a comprehensive workflow management tool tailored for Photography business. We strive to make your work simple. Our platform guarantees that your photography brand stands out at every step.</p>
                <div className={styles.socialicons}>
                  <ul>
                  <li><a href='#'><SlSocialFacebook className={styles.icons} /></a></li>
                    <li><a href='https://twitter.com/hapzea'><RiTwitterXLine className={styles.icons} /></a></li>
                    <li><a href='https://www.instagram.com/hapzea_official/'><SlSocialInstagram className={styles.icons} /></a></li>
                    <li><a href='https://www.linkedin.com/company/hapzea/'><SlSocialLinkedin className={styles.icons} /></a></li>
                  </ul>
                </div>
              </div>
              <div className={styles.product}>
                <h2>Product</h2>
                <ul className={styles.links}>
                  <li><a className="" href="#home">Home</a></li>
                  <li><a className="" href="#">Features</a></li>
                  <li><a className="" href="#">Pricing</a></li>
                  <li><a className="" href="policies.html">Policies, Terms and Conditions</a></li>
                  <li><a className="" href="/desktop_app/download">Download</a></li>
                </ul>
              </div>
              <div className={styles.company}>
                <h2>Company</h2>
                <ul className={styles.links}>
                  <li><a className="" href="aboutus.html">About Us</a></li>
                  <li><a className="" href="#">FAQ's</a></li>
                  <li><a className="" href="#">Support</a></li>
                  {/* <li><a className="" href="/desktop_app/download">Download</a></li> */}
                </ul>
              </div>
              <div className={styles.reach}>
                <h2>Reach us @</h2>
                <ul className={styles.links}>
                  <li><a className="" href="#">+809272561823</a></li>
                  <li><a className="" href="#">hello@hapzea.com</a></li>
                  <li><a className="" href="#">www.hapzea.com</a></li>
                  <li><a className="" href="#">Bangalore,India</a></li>
                </ul>
              </div>
            </div>
         
          </div>
          {/* <div className={styles.borderLine}></div>
          <div>
          <div>1</div>
          <div>2</div>
          </div> */}
          <div className={styles.footer_area}>
            <div className={`${styles.footer_shape} ${styles.footerShape1}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape2}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape3}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape4}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape5}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape6}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape7}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape8}`}></div>
          </div>
          <div>
          <div className={styles.borderLine}></div>
          <div className={styles.footerBottom}>
            <p>Crafted with ❤ in <span>bangalore,India</span></p>
            <p><span>From</span> Retina</p>
          </div>
          </div>
        </footer>
        <footer className={styles.mob_footer}>
          <div className={styles.mob_footerContainer}>
            <div className={styles.mob_footerDetail}>
              <div className={styles.mob_brand}>
                <div className={styles.mob_logo}>
                  <li><a href="#home"><img className='logo_img' src={logo} alt="Logo" /></a></li>
                </div>
                <p>Hapzea is a comprehensive workflow management tool tailored for Photography business. We strive to make your work simple. Our platform guarantees that your photography brand stands out at every step.</p>
                <div className={styles.socialicons}>
                  <ul>
                    <li><a href='#'><SlSocialFacebook className={styles.icons} /></a></li>
                    <li><a href='https://twitter.com/hapzea'><RiTwitterXLine className={styles.icons} /></a></li>
                    <li><a href='https://www.instagram.com/hapzea_official/'><SlSocialInstagram className={styles.icons} /></a></li>
                    <li><a href='https://www.linkedin.com/company/hapzea/'><SlSocialLinkedin className={styles.icons} /></a></li>
                  </ul>
                </div>
              </div>
              <div className={styles.firstRow}>
                <div className={styles.product}>
                  <h2>Product</h2>
                  <ul className={styles.links}>
                    <li><a className="" href="#home">Home</a></li>
                    <li><a className="" href="#">Features</a></li>
                    <li><a className="" href="#">Pricing</a></li>
                    <li><a className="" href="policies.html">Policies, Terms and Conditions</a></li>
                  </ul>
                </div>
                <div className={styles.company}>
                  <h2>Company</h2>
                  <ul className={styles.links}>
                    <li><a className="" href="aboutus.html">About Us</a></li>
                    <li><a className="" href="#">FAQ's</a></li>
                    <li><a className="" href="#">Support</a></li>
                  </ul>
                </div>
              </div>

              <div className={styles.firstRow}>
                <div className={styles.reach}>
                  <h2>Reach us @</h2>
                  <ul className={styles.links}>
                    <li><a className="" href="#">+809272561823</a></li>
                    <li><a className="" href="#">hello@hapzea.com</a></li>
                    <li><a className="" href="#">www.hapzea.com</a></li>
                    <li><a className="" href="#">Bangalore,India</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.mob_footer_area}>
            <div className={`${styles.footer_shape} ${styles.footerShape1}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape2}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape3}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape4}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape5}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape6}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape7}`}></div>
            <div className={`${styles.footer_shape} ${styles.footerShape8}`}></div>
          </div>
          <div>
          <div className={styles.borderLine}></div>
          <div className={styles.footerBottom}>
            <p>Crafted with ❤ in <span>bangalore,India</span></p>
            <p><span>From</span> Retina</p>
          </div>
          </div>
        </footer>

      </div>
    </div>
  );
}

export default Home;