import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Gallery_face.css'; 

export default function Gallery() {
  const { id: eventId } = useParams();
  const [images, setImages] = useState([]);

  useEffect(() => { 
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          'http://localhost:8000/api/v1/user/get-event-images', 
          {
            params: { eventId },
            withCredentials: true // Correct placement of withCredentials
          }
        );
        setImages(response.data.images);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [eventId]);

  return (
    <div className="gallery-container">
      {images.length > 0 ? (
        images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Gallery ${index}`} />
          </div>
        ))
      ) : (
        <p>No images available</p>
      )}
    </div>
  );
}
